/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import LoadBar from "../../../components/loadBar";
import VerifyCurrentUser from "../../../components/verifyCurrentUser";
import { PageContainer, PageHeader, PageTitle } from "../../../global/styles";
import { generalBuyList } from "../../../providers/reports";
import { Container } from "./styles";

const GeneralHome = () => {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    _loadReport();
  }, []);

  async function _loadReport() {
    try {
      setLoading(true);
      const response = await generalBuyList(
        "2010-01-01 01:01:01",
        "2030-01-01 01:01:01"
      );

      _processData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function _processData(rawData) {
    let paymentWays = [];

    if (rawData.length > 0) {
      rawData.map((sale) => {
        let paymentWay = "Outros";

        switch (sale.id_tipo_pagamento) {
          case "2":
            paymentWay = "Cartão de débito";
            break;
          case "3":
            paymentWay = "Dinheiro";
            break;
          case "4":
            paymentWay = "Cartão de crédito";
            break;
          case "5":
            paymentWay = "Cortesia";
            break;
          case "7":
            paymentWay = "Cartão de crédito - Pagseguro";
            break;
          case "8":
            paymentWay = "Cartão de débito - Pagseguro";
            break;
          case "9":
            paymentWay = "Boleto - Pagseguro";
            break;
          case "10":
            paymentWay = "Débito Online - Pagseguro";
            break;
          case "11":
            paymentWay = "Outros - Pagseguro";
            break;
          case "12":
            paymentWay = "Depósito - Pagseguro";
            break;
          default:
            break;
        }

        sale.paymentWay = paymentWay;

        const findPayment = paymentWays.findIndex(
          (payment) => sale.paymentWay === payment.paymentWay
        );

        if (sale.id_status_pgto === "2") {
          if (findPayment === -1) {
            paymentWays.push({
              paymentWay,
              total: Number(sale.valor_compra),
            });
          } else {
            paymentWays[findPayment].total += Number(sale.valor_compra);
          }
        }
        return true;
      });

      console.log("payment", paymentWays);

      const labels = paymentWays.map((payment) => payment.paymentWay);
      const data = paymentWays.map((payment) => payment.total);

      setReportData({
        labels,
        datasets: [
          {
            label: "Value",
            data,
            backgroundColor: [
              "rgb(255, 99, 132)",
              "rgb(54, 162, 235)",
              "rgb(255, 206, 86)",
              "rgb(75, 192, 192)",
              "rgb(153, 102, 255)",
              "rgb(255, 159, 64)",
            ],
            borderColor: [
              "rgba(255, 99, 132, 1)",
              "rgba(54, 162, 235, 1)",
              "rgba(255, 206, 86, 1)",
              "rgba(75, 192, 192, 1)",
              "rgba(153, 102, 255, 1)",
              "rgba(255, 159, 64, 1)",
            ],
            borderWidth: 1,
          },
        ],
      });
    }
  }

  const [reportData, setReportData] = useState({});

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Vendas</PageTitle>
          </PageHeader>
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default GeneralHome;
