import moment from "moment";
import React, { useEffect, useState } from "react";
import { Line } from "react-chartjs-2";

const SaleReportGraph = ({ data }) => {
  const [graphData, setGraphData] = useState({});

  useEffect(() => {
    let minDate = moment().startOf("day");
    let maxDate = moment().endOf("day");

    if (!data) return;
    // eslint-disable-next-line array-callback-return
    data.map((sale) => {
      const saleDate = moment(sale.data_hora_compra, "YYYY-MM-DD");
      if (saleDate.isBefore(minDate)) minDate = saleDate;
      if (saleDate.isAfter(maxDate)) maxDate = saleDate;
    });

    let labels = [];

    let actualDate = moment(minDate);
    actualDate = actualDate.add(1, "hour");

    while (actualDate.isBetween(minDate, maxDate)) {
      labels.push(actualDate.format("DD/MM/YYYY"));
      actualDate.add(1, "day");
    }

    let saleData = [];
    // Percorre dia a dia
    // eslint-disable-next-line array-callback-return
    labels.map((labelDate) => {
      let total = 0;

      // Verifica se teve alguma venda ou vendas nesse dia

      // eslint-disable-next-line array-callback-return
      data.map((sale) => {
        if (
          labelDate ===
          moment(sale.data_hora_compra, "YYYY-MM-DD").format("DD/MM/YYYY")
        )
          total += sale.valor_produto;
      });

      saleData.push(total);
    });

    setGraphData({
      labels,
      datasets: [
        {
          label: "Vendas em R$",
          fill: true,
          lineTension: 0.1,
          backgroundColor: "rgba(75,192,192,0.4)",
          borderColor: "rgba(75,192,192,1)",
          borderCapStyle: "butt",
          borderDash: [],
          borderDashOffset: 0.0,
          borderJoinStyle: "miter",
          pointBorderColor: "rgba(75,192,192,1)",
          pointBackgroundColor: "#fff",
          pointBorderWidth: 1,
          pointHoverRadius: 5,
          pointHoverBackgroundColor: "rgba(75,192,192,1)",
          pointHoverBorderColor: "rgba(220,220,220,1)",
          pointHoverBorderWidth: 2,
          pointRadius: 1,
          pointHitRadius: 10,
          data: saleData,
        },
      ],
    });
  }, [data]);

  return (
    <>
      <Line
        data={graphData}
        width="100"
        height="40"
        options={{ maintainAspectRatio: true, legend: false }}
      />
    </>
  );
};
export default SaleReportGraph;
