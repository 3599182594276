/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import LoadBar from "../../../components/loadBar";
import ReportSalesCategory from "../../../components/reportSalesCategory";
import ReportSalesPayment from "../../../components/reportSalesPayment";
import ReportSalesProduct from "../../../components/reportSalesProduct";
import SaleReportGraph from "../../../components/SaleReportGraph";
import VerifyCurrentUser from "../../../components/verifyCurrentUser";
import {
  ButtonPrimary,
  ColumnLayout,
  LabelText,
  LayoutCell,
  PageContainer,
} from "../../../global/styles";
import LocalStorageObject from "../../../providers/localStorageObject";
import { getAllSales } from "../../../providers/reports";
import { DateSelect } from "../shop/events/eventAdd/styles";
import { Container } from "./styles";

const Home = () => {
  const [loading, setLoading] = useState(false);
  const [rawData, setRawData] = useState([]);
  const [rawCategoryData, setRawCategoryData] = useState([]);
  const [rawPaymentData, setRawPaymentData] = useState([]);
  const [rawGraphData, setRawGraphData] = useState([]);
  const [filterCompanyIds, setFilterCompanyIds] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState("");
  const [filterEndDate, setFilterEndDate] = useState("");

  useEffect(() => {
    _loadCompanies();
  }, []);

  async function _loadCompanies() {
    const companiesData = LocalStorageObject.companies.get();
    if (!companiesData) return;
    let companiesOptions = [];
    companiesOptions.push({ value: "", label: "Todas" });
    companiesData.map((company) => {
      companiesOptions.push({
        value: company.id,
        label: company.nome_balada,
      });
      return true;
    });

    setFilterStartDate(moment().startOf("day").format("MM/DD/YYYY HH:mm:ss"));
    setFilterEndDate(moment().endOf("day").format("MM/DD/YYYY HH:mm:ss"));

    _loadReport([]);
  }

  async function _loadReport(companiesIds, startDate, endDate) {
    try {
      setLoading(true);
      const response = await getAllSales(startDate, endDate, companiesIds);

      setRawCategoryData(response);
      setRawData(response);
      setRawPaymentData(response);
      setRawGraphData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleClickFilter() {
    console.log("filterCompanyIds", filterCompanyIds);
    _loadReport(filterCompanyIds, filterStartDate, filterEndDate);
  }

  function handleChangeCompanies(value) {
    if (value) {
      let todas = false;
      let ids = [];
      value.map((id) => {
        if (id.value === "") todas = true;
        return true;
      });
      if (todas) {
        allCompanyIds.map((company) => {
          ids.push(company.id);
          return true;
        });
      } else {
        ids = value.map((id) => id.value);
      }

      setFilterCompanyIds(ids);
    } else {
      setFilterCompanyIds([]);
    }
  }

  let companyIds = [
    {
      value: "",
      label: "Todas",
    },
  ];
  let allCompanyIds = [];
  if (LocalStorageObject.companies.get()) {
    allCompanyIds = LocalStorageObject.companies.get().map((company) => {
      companyIds.push({
        value: company.id,
        label: company.nome_balada,
      });
      return company;
    });
  }

  const companiesOptions = companyIds;

  return (
    <>
      <Container>
        <PageContainer>
          <ColumnLayout>
            <LayoutCell>
              <Select
                options={companiesOptions}
                isMulti={true}
                isSearchable={true}
                onChange={handleChangeCompanies}
                placeholder="Selecione a empresa..."
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>Data início</LabelText>
              <DateSelect
                onChange={(event) => {
                  setFilterStartDate(event.format("MM/DD/YYYY HH:mm:ss"));
                }}
                locale="pt-br"
                closeOnSelect={true}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                defaultValue={moment().startOf("day")}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data final</LabelText>
              <DateSelect
                onChange={(event) => {
                  setFilterEndDate(event.format("MM/DD/YYYY HH:mm:ss"));
                }}
                locale="pt-br"
                closeOnSelect={true}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                defaultValue={moment().endOf("day")}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ButtonPrimary onClick={handleClickFilter}>Filtrar</ButtonPrimary>
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <SaleReportGraph
                data={rawGraphData}
                filterStartDate={filterStartDate}
                filterEndDate={filterEndDate}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ReportSalesProduct rawData={rawData} />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ReportSalesCategory rawCategoryData={rawCategoryData} />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ReportSalesPayment rawPaymentData={rawPaymentData} />
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default Home;
