/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Datatable from "../../../../components/datatable";
import LoadBar from "../../../../components/loadBar";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../global/styles";
import { getGeneralCompanies } from "../../../../providers/company";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { Container } from "./styles";

const CompanyList = () => {
  const history = useHistory();

  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const response = LocalStorageObject.companies.get();
    console.log("response", response);
    setCompanies(response);
    _reloadCompanies();
  }, []);

  async function _reloadCompanies() {
    try {
      setLoading(true);
      const companyData = await getGeneralCompanies();
      LocalStorageObject.companies.set(companyData);
      console.log("companyData", companyData);
      setCompanies(companyData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Empresa",
      accessor: "nome_balada",
    },
    {
      Header: "Código autorização",
      accessor: "senha_gerente",
    },
    {
      Header: "Ações",
      accessor: "",
    },
  ]);

  function navigateToAdd() {
    history.push("/company/add");
  }

  function edit(id) {
    history.push(`/company/edit/${id}`);
  }

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Empresas</PageTitle>
            <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
          </PageHeader>
          <Datatable columns={columns} data={companies} edit={edit} />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default CompanyList;
