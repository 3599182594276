/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import Inputmask from "inputmask";
import * as moment from "moment";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
} from "../../../../global/styles";
import { insert, verifyEmail } from "../../../../providers/user";
import { Container } from "./styles";

const UserAdd = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [cpf, setCpf] = useState("");
  const [dataNascimento, setDataNascimento] = useState("");
  const [password, setPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");

  const toast = useAlert();
  const history = useHistory();

  function navigateToBack() {
    history.goBack();
  }

  function handleSubmit(e) {
    e.preventDefault();
    _saveUser();
  }

  useEffect(() => {
    Inputmask({ alias: "datetime", inputFormat: "dd/mm/yyyy" }).mask(
      document.getElementById("inputDataNascimento")
    );
    Inputmask({ mask: "999.999.999-99" }).mask(
      document.getElementById("inputCpf")
    );
  }, []);

  async function _saveUser() {
    try {
      setLoading(true);

      if (password !== repeatPassword) throw new Error("Senhas não conferem");
      const response = await verifyEmail(email);
      if (!response) throw new Error("Email já cadastrado");
      let dataNasc = moment(dataNascimento, "DD/MM/YYYY").format("YYYY-MM-DD");

      console.log("dataNascimento", dataNascimento);

      console.log("dataNasc", dataNasc);

      let cpfUnmasked = Inputmask.unmask(cpf, { mask: "999.999.999-99" });

      let data = {
        email,
        nome: name,
        sexo: "3",
        usuarioFirebaseChat: email,
        idUltimoContratoAssinado: "1",
        dataNascimento: dataNasc,
        cpf: cpfUnmasked,
        senha: password,
      };

      const inserted = await insert(data);
      if (!inserted) throw new Error("Erro ao cadastrar o usuário");
      history.goBack();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <PageContainer>
          <PageHeader>
            <PageTitle>Adicionar usuário</PageTitle>
            <ButtonDefault type="button" onClick={navigateToBack}>
              Voltar
            </ButtonDefault>
            {(() => {
              if (loading) {
                return (
                  <ButtonDefault
                    type="button"
                    style={{ marginLeft: 20, width: 80 }}
                  >
                    <FeatherIcon
                      className="App-icon"
                      icon="refresh-cw"
                      size={14}
                    />
                  </ButtonDefault>
                );
              }
              return (
                <ButtonSuccess
                  type="submit"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  Salvar
                </ButtonSuccess>
              );
            })()}
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Nome completo <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setName(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Email <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setEmail(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                CPF <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                id="inputCpf"
                onChange={(event) => setCpf(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Data Nascimento <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                id="inputDataNascimento"
                onChange={(event) => setDataNascimento(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Senha <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setPassword(event.target.value)}
                required={true}
                type="password"
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Repita a senha<RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setRepeatPassword(event.target.value)}
                required={true}
                type="password"
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
    </>
  );
};
export default UserAdd;
