import Datetime from "react-datetime";
import styled from "styled-components";
import theme from "../../../../../global/theme";

export const Container = styled.form`
  display: flex;
`;

export const PageContent = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DateSelect = styled(Datetime)`
  border-radius: 5px;
  padding: 10px;
  background-color: ${theme.lightColor};
  font-size: 16px;
  color: ${theme.textColor};
  margin-top: 10px;
`;
