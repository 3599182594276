const SessionStorageObject = {
  get(key) {
    const rawObj = sessionStorage.getItem(key);
    return JSON.parse(rawObj);
  },
  set(key, obj) {
    sessionStorage.setItem(key, JSON.stringify(obj));
    return true;
  },
};

export default SessionStorageObject;
