import styled from "styled-components";
import { SelectInput, SelectOption } from "../../global/styles";
import theme from "../../global/theme";

export const Container = styled.nav`
  width: 250px;
  height: calc(100vh - 60px);
  background-color: ${theme.lightColor};
  display: flex;
  flex-direction: column;
  position: fixed;
  margin-top: 60px;
  overflow-y: auto;
`;

export const List = styled.ul`
  margin-left: 15px;
  margin-right: 15px;
`;

export const ListItemHeader = styled.li`
  background-color: ${theme.primaryColor};
  border-radius: 5px;
  padding: 10px;
  font-weight: bold;
  color: ${theme.lightColor};
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background-color: ${theme.darkColor};
  }
`;

export const ListItem = styled.li`
  background-color: ${theme.defaultBackgroundColor};
  border-radius: 5px;
  padding: 10px;
  font-size: 14px;
  font-weight: bold;
  color: ${theme.textColor};
  margin-bottom: 10px;
  margin-left: 10px;
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background-color: ${theme.textColor};
    color: ${theme.lightColor};
  }
`;

export const Subtitle = styled.label`
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 20px;
  margin-bottom: 8px;
  font-weight: 400;
  color: ${theme.textColor};
`;

export const Company = styled(SelectInput)`
  margin-left: 15px;
  margin-right: 15px;
  margin-bottom: 5px;
  background-color: ${theme.primaryColor};
  color: ${theme.lightColor};
`;

export const CompanyOption = styled(SelectOption)``;
