import React from "react";
import { useTable } from "react-table";
import { ButtonEdit, ButtonRemove, Table, Td, Th, Thead, Tr } from "./styles";

const Datatable = ({
  columns,
  data,
  edit,
  remove,
  sync,
  editFunction,
  removeFunction,
}) => {
  const {
    getTableProps, // table props from react-table
    getTableBodyProps, // table body props from react-table
    headerGroups, // headerGroups, if your table has groupings
    rows, // rows for the table based on the data passed
    prepareRow, // Prepare the row (this function needs to be called for each row before getting the row props)
  } = useTable({
    columns,
    data,
  });

  return (
    <Table {...getTableProps()}>
      <Thead>
        {headerGroups.map((headerGroup) => (
          <Tr {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column) => {
              return (
                <Th {...column.getHeaderProps()}>{column.render("Header")}</Th>
              );
            })}
          </Tr>
        ))}
      </Thead>
      <tbody {...getTableBodyProps()}>
        {rows.map((row, i) => {
          prepareRow(row);
          return (
            <tr {...row.getRowProps()}>
              {row.cells.map((cell) => {
                if (cell.column.Header === "Ações") {
                  return (
                    <Td {...cell.getCellProps()} key={cell.row.id}>
                      {(() => {
                        if (removeFunction) {
                          return (
                            <ButtonEdit
                              style={{ marginRight: 10 }}
                              onClick={() => {
                                removeFunction(
                                  cell.row.original.qr_code_usuario
                                );
                              }}
                            >
                              Remover função
                            </ButtonEdit>
                          );
                        }
                      })()}
                      {(() => {
                        if (editFunction) {
                          return (
                            <ButtonEdit
                              style={{ marginTop: 10 }}
                              onClick={() => {
                                editFunction(cell.row.original.id);
                              }}
                            >
                              Alterar função
                            </ButtonEdit>
                          );
                        }
                      })()}
                      {(() => {
                        if (edit) {
                          return (
                            <ButtonEdit
                              onClick={() => {
                                edit(cell.row.original.id);
                              }}
                            >
                              Editar
                            </ButtonEdit>
                          );
                        }
                      })()}
                      {(() => {
                        if (remove) {
                          return (
                            <ButtonRemove
                              style={{ marginLeft: 10 }}
                              onClick={() => {
                                remove(cell.row.original.id);
                              }}
                            >
                              Remover
                            </ButtonRemove>
                          );
                        }
                      })()}
                      {(() => {
                        if (sync) {
                          return (
                            <ButtonEdit
                              onClick={() => {
                                sync(cell.row.original.id);
                              }}
                            >
                              Sincronizar
                            </ButtonEdit>
                          );
                        }
                      })()}
                    </Td>
                  );
                } else {
                  return (
                    <Td {...cell.getCellProps()}>{cell.render("Cell")}</Td>
                  );
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </Table>
  );
};
export default Datatable;
