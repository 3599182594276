import styled from "styled-components";
import theme from "./theme";

export const Card = styled.div`
  border-radius: 8px;
  padding: 20px;
`;

export const InputText = styled.input`
  border-radius: 5px;
  padding: 10px;
  background-color: ${theme.lightColor};
  font-size: 16px;
  color: ${theme.textColor};
`;

export const InputTextArea = styled.textarea`
  border-radius: 5px;
  padding: 10px;
  background-color: ${theme.lightColor};
  font-size: 16px;
  color: ${theme.textColor};
`;

export const LabelText = styled.label`
  color: ${theme.textColor};
`;

export const RequiredLabel = styled.span`
  font-size: 12px;
  color: ${theme.dangerColor};
  margin-left: 5px;
`;

export const ButtonPrimary = styled.button`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: ${theme.lightColor};
  font-weight: 600;
  background-color: ${theme.primaryColor};
  transition: 0.5s;
  cursor: pointer;
  :hover {
    background-color: ${theme.darkColor};
  }
`;

export const ButtonSuccess = styled.button`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: ${theme.lightColor};
  font-weight: 600;
  background-color: ${theme.successColor};
  transition: 0.5s;
  cursor: pointer;
  :hover {
    background-color: ${theme.successDarkColor};
  }
`;

export const ButtonDanger = styled.button`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: ${theme.lightColor};
  font-weight: 600;
  background-color: ${theme.dangerColor};
  transition: 0.5s;
  cursor: pointer;
  :hover {
    background-color: ${theme.dangerDarkColor};
  }
`;

export const ButtonDefault = styled.button`
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  color: ${theme.lightColor};
  font-weight: 600;
  background-color: ${theme.defaultColor};
  cursor: pointer;
  transition: 0.5s;
  :hover {
    background-color: ${theme.defaultDarkColor};
  }
`;

export const SelectInput = styled.select`
  border-radius: 5px;
  padding: 10px;
  -webkit-appearance: none;
  cursor: pointer;
  font-weight: bold;
  color: ${theme.textColor};
  font-size: 14px;
`;

export const SelectOption = styled.option`
  font-weight: bold;
  background-color: ${theme.defaultBackgroundColor};
  color: ${theme.textColor};
`;

export const HiddenFileInput = styled.input`
  display: none;
`;

export const PageContainer = styled.div`
  margin-top: 80px;
  margin-right: 20px;
  margin-left: 270px;
  width: 100%;
`;

export const PageHeader = styled.div`
  padding: 15px;
  background-color: ${theme.primaryColor};
  border-radius: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const PageTitle = styled.h1`
  color: ${theme.lightColor};
  flex: 1;
  font-size: 20px;
  margin: 0px;
`;

export const ColumnLayout = styled.div`
  display: flex;
  flex-direction: row;
`;

export const LayoutCell = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  padding: 10px;
`;

export const PrimaryPainelHeader = styled.div`
  background-color: ${theme.primaryLightColor};
  padding: 15px;
  color: #000;
  border-top-right-radius: 5px;
  border-top-left-radius: 5px;
`;

export const PrimaryPainelBody = styled.div`
  border: 1px solid ${theme.primaryLightColor};
  padding: 15px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
`;
