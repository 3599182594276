import React from "react";
import { Bar, Container } from "./styles";

const LoadBar = () => {
  return (
    <Container>
      <Bar className="App-loadbar" />
    </Container>
  );
};
export default LoadBar;
