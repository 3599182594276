/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useHistory } from "react-router-dom";
import Datatable from "../../../../components/datatable";
import LoadBar from "../../../../components/loadBar";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../global/styles";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { getAllTerminals } from "../../../../providers/terminal";
import { Container } from "./styles";

const TerminalList = () => {
  const history = useHistory();

  const [terminals, setTerminals] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const response = LocalStorageObject.terminals.get();
    if (response) {
      setTerminals(response);
    }
    _reloadTerminals();
  }, []);

  async function _reloadTerminals() {
    try {
      setLoading(true);
      const terminalsData = await getAllTerminals();
      LocalStorageObject.terminals.set(terminalsData);
      setTerminals(terminalsData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Terminal #",
      accessor: "id_terminal",
    },
    {
      Header: "Empresa",
      accessor: "nome_balada",
    },
    {
      Header: "Ações",
      accessor: "",
    },
  ]);

  function navigateToAdd() {
    history.push("/general/terminals/add");
  }

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Terminais</PageTitle>
            <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
          </PageHeader>
          <Datatable columns={columns} data={terminals} />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default TerminalList;
