/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LocalStorageObject from "../../providers/localStorageObject";
import { getMenu } from "../../providers/user";
import {
  Company,
  CompanyOption,
  Container,
  List,
  ListItem,
  ListItemHeader,
  Subtitle,
} from "./styles";

const SideMenu = () => {
  const [menuItems, setMenuItems] = useState([]);
  const [companies, setCompanies] = useState([]);
  const [user, setUser] = useState({
    id_perfil_usuario: "12",
  });

  const history = useHistory();

  useEffect(() => {
    getStorage();
    _loadMenu();
    _getCompanies();
    _getUser();
  }, []);

  function getStorage() {
    const localData = LocalStorageObject.menuItems.get();
    if (localData) setMenuItems(localData);
  }

  async function _getCompanies() {
    const companiesData = LocalStorageObject.companies.get();
    setCompanies(companiesData);
  }

  async function _getUser() {
    const userData = LocalStorageObject.currentUser.get();
    setUser(userData);
  }

  async function _loadMenu() {
    const response = await getMenu();
    LocalStorageObject.menuItems.set(response);
    getStorage();
  }

  function navigation(route) {
    console.log("route", route);
    switch (route) {
      case "ownerHome":
        history.push("/");
        break;
      case "categoriaproduto":
        history.push("/product/category/list");
        break;
      case "eventos":
        history.push("/event/list");
        break;
      case "produtos":
        history.push("/product/list");
        break;
      case "companies":
        history.push("/company/list");
        break;
      case "ownerStock":
        history.push("/stock/report");
        break;
      case "ownerProducts":
        history.push("/general/product/list");
        break;
      case "ownerTerminals":
        history.push("/general/terminals/list");
        break;
      case "ownerUsers":
        history.push("/general/user/list");
        break;
      case "onwerSalesTransfer":
        history.push("/general/sales/transfer/report");
        break;
      case "ownerClients":
        history.push("/general/client/list");
        break;
      default:
        break;
    }
  }

  return (
    <Container>
      {(() => {
        if (user.id_perfil_usuario !== "12") {
          return (
            <>
              <Subtitle>Empresa</Subtitle>
              <Company>
                {companies.map((company) => {
                  return <CompanyOption>{company.nome_balada}</CompanyOption>;
                })}
              </Company>
            </>
          );
        }
      })()}

      <List>
        <ListItemHeader
          onClick={() => {
            navigation("ownerHome");
          }}
        >
          <FeatherIcon icon="home" size={18} style={{ marginRight: 10 }} />
          Home
        </ListItemHeader>
        {(() => {
          return menuItems.map((menuItem) => {
            return (
              <div key={menuItem.id_categoria_menu}>
                <ListItemHeader
                  onClick={() => {
                    navigation(menuItem.href);
                  }}
                >
                  <FeatherIcon
                    icon={menuItem.icone}
                    size={18}
                    style={{ marginRight: 10 }}
                  />
                  {menuItem.descricao_categoria_menu}
                </ListItemHeader>
                {menuItem.filhos.map((subItem) => {
                  return (
                    <ListItem
                      onClick={() => {
                        navigation(subItem.href);
                      }}
                      key={subItem.id}
                    >
                      {subItem.descricao}
                    </ListItem>
                  );
                })}
              </div>
            );
          });
        })()}
      </List>
    </Container>
  );
};
export default SideMenu;
