import styled from "styled-components";
import {
  ButtonDefault,
  ButtonPrimary,
  Card,
  InputText,
  LabelText,
} from "../../../global/styles";
import theme from "../../../global/theme";

export const Container = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
`;

export const CardContainer = styled(Card)`
  background-color: ${theme.defaultBackgroundColor};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const FormLogin = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Input = styled(InputText)`
  width: 300px;
  margin-top: 10px;
`;

export const Label = styled(LabelText)`
  margin-top: 15px;
`;

export const Button = styled(ButtonPrimary)`
  margin-top: 25px;
`;

export const ButtonLoading = styled(ButtonDefault)`
  margin-top: 25px;
`;

export const Logo = styled.img`
  margin-top: 15px;
`;

export const Title = styled.h2`
  color: ${theme.textColor};
`;
