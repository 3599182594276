import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Login from "../pages/auth/login";
import OwnerRoutes from "./ownerRoutes";

const Router = () => {
  return (
    <BrowserRouter>
      <Switch>
        <Route path="/login">
          <Login />
        </Route>
        <OwnerRoutes />
      </Switch>
    </BrowserRouter>
  );
};
export default Router;
