import microservice from "../services/microservice";
import { FormatCPFCNPJ } from "../services/utils";
import LocalStorageObject from "./localStorageObject";

export async function getClients() {
  return new Promise(async (resolve, reject) => {
    const user = LocalStorageObject.currentUser.get();

    microservice
      .post("clients/viewAll", {
        email: user.email,
        token: user.token_login,
      })
      .then((result) => {
        let clients = result.data;
        clients = clients.map((client) => {
          client.cpf_cnpj = FormatCPFCNPJ(client.cpf_cnpj);
          return client;
        });

        resolve(clients);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}
