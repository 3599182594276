import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import config from "../../config";
import { PageHeader, PageTitle } from "../../global/styles";
import { TdImage, TdName, TdNumber, TdStatus, Th, ThNumber } from "./styles";

function ReportStock({ rawData }) {
  useEffect(() => {
    _processData(rawData);
  }, [rawData]);

  const [companies, setCompanies] = useState([]);

  function _processData(stockData) {
    let companies = [];

    stockData.map((stock) => {
      let index = companies.findIndex(
        (product) => product.empresa === stock.empresa
      );

      stock.image = config.apiImageURL + stock.url_foto;
      stock.icon = "check-circle";
      stock.color = "green";

      if (stock.quantidade_estoque < stock.estoque_minimo) {
        stock.icon = "alert-circle";
        stock.color = "red";
      }

      if (index === -1) {
        companies.push({
          empresa: stock.empresa,
          products: [stock],
        });
      } else {
        companies[index].products.push(stock);
      }

      return true;
    });

    setCompanies(companies);
  }

  return (
    <>
      {companies.map((company) => {
        return (
          <div key={company.empresa} className="margin-top">
            <PageHeader>
              <PageTitle>{company.empresa}</PageTitle>
            </PageHeader>
            <table width="100%">
              <thead>
                <tr>
                  <Th></Th>
                  <Th>Produto</Th>
                  <ThNumber>Qtd Mín</ThNumber>
                  <ThNumber>Qtd Ideal</ThNumber>
                  <ThNumber>Qtd Atual</ThNumber>
                  <Th>Status</Th>
                </tr>
              </thead>
              <tbody>
                {company.products.map((product) => {
                  return (
                    <tr key={product.id.toString()}>
                      <TdImage>
                        <img
                          alt={product.nome}
                          src={product.image}
                          width="40"
                        />
                      </TdImage>
                      <TdName>{product.nome}</TdName>
                      <TdNumber>{product.estoque_minimo}</TdNumber>
                      <TdNumber>{product.estoque_ideal}</TdNumber>
                      <TdNumber>{product.quantidade_estoque}</TdNumber>
                      <TdStatus>
                        <FeatherIcon
                          icon={product.icon}
                          size={22}
                          style={{ color: product.color }}
                        />
                      </TdStatus>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        );
      })}
    </>
  );
}

export default ReportStock;
