import moment from "moment";
import api from "../services/backend";
import microservice from "../services/microservice";
import LocalStorageObject from "./localStorageObject";

export async function getMenu() {
  try {
    let user = LocalStorageObject.currentUser.get();

    const response = await api.post("usuario/getmenu", {
      email: user.email,
      token: user.token_login,
    });
    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return [];
  }
}

export async function get(id) {
  try {
    let user = LocalStorageObject.currentUser.get();

    const response = await microservice.post("user/view/" + id, {
      email: user.email,
      token: user.token_login,
    });
    let userData = response.data;
    userData.data_nasc = moment(userData.data_nasc, "YYYY-MM-DD").format(
      "DD/MM/YYYY"
    );

    return userData;
  } catch (error) {
    return false;
  }
}

export async function getAll() {
  try {
    let user = LocalStorageObject.currentUser.get();

    const response = await microservice.post("user/viewAll", {
      email: user.email,
      token: user.token_login,
    });
    let users = response.data;
    users = users.map((user) => {
      user.data_nasc = moment(user.data_nasc, "YYYY-MM-DD").format(
        "DD/MM/YYYY"
      );
      return user;
    });
    return users;
  } catch (error) {
    return [];
  }
}

export async function verifyEmail(email) {
  try {
    const response = await api.post("usuario/verifyemailexists", {
      email,
    });
    if (response.data.reason === "success") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function checkoutUser(qrCode) {
  try {
    let user = LocalStorageObject.currentUser.get();

    const response = await api.post("usuario/checkoutcooperator", {
      email: user.email,
      token: user.token_login,
      qrCode,
    });
    console.log(response.data);
    if (response.data.reason === "success") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function checkinUser(qrCode, idBalada, idPerfilUsuario) {
  try {
    let user = LocalStorageObject.currentUser.get();

    const response = await api.post("usuario/checkin", {
      email: user.email,
      token: user.token_login,
      qrCode,
      idBalada,
      idPerfilUsuario,
    });
    console.log(response.data);
    if (response.data.reason === "success") {
      return true;
    } else {
      return false;
    }
  } catch (error) {
    return false;
  }
}

export async function insert({
  email,
  nome,
  sexo,
  usuarioFirebaseChat,
  idUltimoContratoAssinado,
  dataNascimento,
  cpf,
  senha,
}) {
  try {
    const response = await api.post("usuario/insert", {
      email,
      nome,
      sexo,
      usuarioFirebaseChat,
      idUltimoContratoAssinado,
      dataNascimento,
      cpf,
      senha,
    });
    console.log(response.data);
    if (response.data.reason === "success") {
      return true;
    }
    return false;
  } catch (error) {
    return false;
  }
}
