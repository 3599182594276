/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import LocalStorageObject from "../../providers/localStorageObject";

const VerifyCurrentUser = () => {
  let history = useHistory();

  useEffect(() => {
    _verifyCurrentUser();
  }, []);

  function _verifyCurrentUser() {
    const currentUser = LocalStorageObject.currentUser.get();
    if (!currentUser) history.replace("/login");
  }

  return <></>;
};
export default VerifyCurrentUser;
