import styled from "styled-components";

export const Container = styled.div`
  flex: 1;
`;

export const ListItem = styled.div`
  background-color: #fff;
  border-radius: 5px;
  color: #222;
  padding: 10px;
  margin-top: 10px;
  display: flex;
  flex-direction: row;
`;

export const ListValue = styled.span`
  flex: 1;
  text-align: right;
  color: #333;
  font-weight: bold;
`;

export const ListQty = styled.span`
  flex: 1;
  color: #333;
  font-weight: bold;
`;

export const ListName = styled.span`
  flex: 4;
`;

export const Th = styled.th`
  text-align: left;
  padding: 10px;
`;

export const ThNumber = styled.th`
  text-align: right;
  padding: 10px;
`;

export const TdNumber = styled.td`
  text-align: right;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
  width: 25%;
`;

export const Td = styled.td`
  text-align: left;
  border-radius: 5px;
  padding: 10px;
  background-color: #fff;
`;
