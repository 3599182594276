/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
  SelectInput,
} from "../../../../global/styles";
import { getGeneralCompanies } from "../../../../providers/company";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { checkinUser, get } from "../../../../providers/user";
import { Container } from "./styles";

const ChangeFunction = () => {
  const [loading, setLoading] = useState(false);
  const [role, setRole] = useState("");
  const [user, setUser] = useState({
    nome: "",
    email: "",
    data_nasc: "",
    cpf: "",
    perfil_usuario: "",
    qr_code_usuario: "",
  });
  const [companyId, setCompanyId] = useState("");
  const [companies, setCompanies] = useState([]);

  const { id } = useParams();

  const toast = useAlert();
  const history = useHistory();

  function navigateToBack() {
    history.goBack();
  }

  function handleSubmit(e) {
    e.preventDefault();
    _changeFunction();
  }

  useEffect(() => {
    const response = LocalStorageObject.companies.get();
    setCompanies(response);
    _reloadCompanies();
    _loadUser();
  }, []);

  async function _reloadCompanies() {
    try {
      setLoading(true);
      const companyData = await getGeneralCompanies();
      LocalStorageObject.companies.set(companyData);
      setCompanies(companyData);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function _loadUser() {
    try {
      setLoading(true);
      const userData = await get(id);
      setUser(userData);
    } catch (error) {
      toast.error("Usuário não encontrado");
      history.goBack();
    } finally {
      setLoading(false);
    }
  }

  async function _changeFunction() {
    try {
      setLoading(true);
      const result = await checkinUser(user.qr_code_usuario, companyId, role);
      if (!result) throw new Error("Erro ao alterar a função do usuário");
      history.goBack();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <PageContainer>
          <PageHeader>
            <PageTitle>Editar função do usuário</PageTitle>
            <ButtonDefault type="button" onClick={navigateToBack}>
              Voltar
            </ButtonDefault>
            {(() => {
              if (loading) {
                return (
                  <ButtonDefault
                    type="button"
                    style={{ marginLeft: 20, width: 80 }}
                  >
                    <FeatherIcon
                      className="App-icon"
                      icon="refresh-cw"
                      size={14}
                    />
                  </ButtonDefault>
                );
              }
              return (
                <ButtonSuccess
                  type="submit"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  Salvar
                </ButtonSuccess>
              );
            })()}
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>Nome completo</LabelText>
              <InputText
                value={user.nome}
                disabled={true}
                style={{ marginTop: 10, backgroundColor: "#cecece" }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Email</LabelText>
              <InputText
                value={user.email}
                disabled={true}
                style={{ marginTop: 10, backgroundColor: "#cecece" }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>CPF</LabelText>
              <InputText
                value={user.cpf}
                disabled={true}
                style={{ marginTop: 10, backgroundColor: "#cecece" }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data Nascimento</LabelText>
              <InputText
                value={user.data_nasc}
                disabled={true}
                style={{ marginTop: 10, backgroundColor: "#cecece" }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Função atual</LabelText>
              <InputText
                value={user.perfil_usuario}
                disabled={true}
                style={{ marginTop: 10, backgroundColor: "#cecece" }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Empresa <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <SelectInput
                style={{ marginTop: 10 }}
                required={true}
                onChange={(event) => setCompanyId(event.target.value)}
              >
                <option value="">Selecione</option>
                {(() => {
                  return companies.map((company) => {
                    return (
                      <option key={company.id.toString()} value={company.id}>
                        {company.nome_balada}
                      </option>
                    );
                  });
                })()}
              </SelectInput>
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Função <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <SelectInput
                style={{ marginTop: 10 }}
                required={true}
                onChange={(event) => setRole(event.target.value)}
              >
                <option value="">Selecione</option>
                <option value="2">Administrador</option>
                <option value="10">Vendedor</option>
              </SelectInput>
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
    </>
  );
};
export default ChangeFunction;
