import styled from "styled-components";
import theme from "../../global/theme";

export const Container = styled.nav`
  width: 100%;
  height: 60px;
  background-color: ${theme.primaryColor};
  display: flex;
  align-items: center;
  position: fixed;
  justify-content: space-between;
  z-index: 100;
`;

export const Logo = styled.img`
  height: 40px;
  margin-left: 20px;
`;

export const UserContainer = styled.div`
  display: flex;
  flex-direction: row;
  cursor: pointer;
`;

export const UserData = styled.div`
  display: flex;
  flex-direction: column;
  text-align: right;
  color: ${theme.lightColor};
`;

export const UserName = styled.span`
  font-weight: bold;
  font-size: 18px;
  margin-top: 5px;
`;

export const UserEmail = styled.span``;

export const UserPhoto = styled.img`
  width: 50px;
  height: 50px;
  border-radius: 25px;
  margin-left: 20px;
  margin-right: 20px;
`;

export const UserMenuContainer = styled.div`
  position: absolute;
  width: auto;
  height: auto;
  background-color: ${theme.defaultBackgroundColor};
  top: 59px;
  right: 20px;
  box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.4);
`;

export const MenuUl = styled.ul`
  margin: 0px;
`;

export const MenuLi = styled.li`
  padding: 15px 30px;
  cursor: pointer;
  transition: 0.5s;
  :hover {
    color: ${theme.lightColor};
    background-color: ${theme.darkColor};
  }
`;
