import api from "../services/backend";
import microservice from "../services/microservice";
import LocalStorageObject from "./localStorageObject";

export async function getAllSales(startDate, endDate, companies) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const response = await microservice.post("reports/sales/paid", {
      email: user.email,
      token: user.token_login,
      startDate,
      endDate,
      companies,
    });

    console.log("response", response);

    return response.data;
  } catch (error) {}
}

export async function getStockQuantity(companies) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const response = await microservice.post("reports/stock/qty", {
      email: user.email,
      token: user.token_login,
      companies,
    });

    return response.data;
  } catch (error) {}
}

export async function getSalesTransfer(company, startDate, endDate) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const data = {
      email: user.email,
      token: user.token_login,
      company,
      startDate,
      endDate,
    };

    const response = await microservice.post("reports/sales/transfer", data);

    return response.data;
  } catch (error) {
    console.log(error);
    return [];
  }
}

export async function generalBuyList(dataInicial, dataFinal) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("relatorios/ownerbuygenerallist", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      dataInicial,
      dataFinal,
    });

    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return [];
  }
}
