import Axios from "axios";
import moment from "moment";
import config from "../config";
import api from "../services/backend";
import LocalStorageObject from "./localStorageObject";

export async function saveEvent(
  tituloEvento,
  descricao,
  local,
  estado,
  cidade,
  latitude,
  longitude,
  endereco,
  funcionamentoPadrao,
  dataHoraInicioEvento,
  dataHoraFimEvento,
  dataInicioEventoVisualizacao,
  dataFinalEventoVisualizacao
) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const actualDateTime = moment().format("YYYY-MM-DD HH:mm:ss");

    let data = {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      tituloEvento,
      descricao,
      local,
      estado,
      cidade,
      latitude,
      longitude,
      endereco,
      funcionamentoPadrao,
      dataHoraInicioReserva: actualDateTime,
      dataHoraFimReserva: actualDateTime,
      dataHoraInicioEvento,
      dataHoraFimEvento,
      dataInicioEventoVisualizacao,
      dataFinalEventoVisualizacao,
    };

    const response = await api.post("evento/insert", data);

    if (response.data.reason === "success") {
      return true;
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}

export async function getEvents() {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("evento/viewbybalada", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      orderBy: "id",
      direction: "desc",
      todos: "true",
    });

    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return [];
  }
}

export async function getAddress(searchStr) {
  try {
    const response = await Axios.get(
      `${config.geoDataBaseURL}json?key=${config.geoDataApiKey}&q=${searchStr}&pretty=1&no_annotations=1`
    );

    console.log(response.data.results);

    return response.data.results;
  } catch (error) {
    return [];
  }
}
