import api from "../services/backend";
import LocalStorageObject from "./localStorageObject";

export async function getCategories() {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("categoriaproduto/view", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
    });

    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return [];
  }
}

export async function getById(id) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("categoriaproduto/viewbyid", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      id,
    });

    if (response.data.reason === "success") {
      return response.data.data.view[0];
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}

export async function editCategory(id, descricao) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("categoriaproduto/update", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      id,
      descricao,
    });

    if (response.data.reason === "success") {
      return true;
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}

export async function saveCategory(name) {
  try {
    const user = LocalStorageObject.currentUser.get();
    const currentCompany = LocalStorageObject.currentCompany.get();

    const response = await api.post("categoriaproduto/insert", {
      email: user.email,
      token: user.token_login,
      idBalada: currentCompany.id,
      descricao: name,
    });

    if (response.data.reason === "success") {
      return true;
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}
