/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import Inputmask from "inputmask";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import LoadBar from "../../../../components/loadBar";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
  SelectInput,
} from "../../../../global/styles";
import { getCep } from "../../../../providers/cep";
import { getAllCities } from "../../../../providers/city";
import { createCompany } from "../../../../providers/company";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { getAll } from "../../../../providers/user";
import { Container } from "./styles";

const CompanyAdd = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [codigoAtivacao, setCodigoAtivacao] = useState("");
  const [idEnotas, setIdEnotas] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inscMunicipal, setInscMunicipal] = useState("");
  const [inscEstadual, setInscEstadual] = useState("");
  const [telefoneComercial, setTelefoneComercial] = useState("");
  const [simples, setSimples] = useState("");
  const [complemento, setComplemento] = useState("");
  const [emailPagseguro, setEmailPagseguro] = useState("");
  const [estado, setEstado] = useState("");
  const [codigoIbge, setCodigoIbge] = useState("");
  const [emailAdmin, setEmailAdmin] = useState("");
  const [users, setUsers] = useState([]);

  const history = useHistory();
  const alert = useAlert();

  useEffect(() => {
    Inputmask({ mask: "99999-999" }, { autoUnmask: true }).mask(
      document.getElementById("inputCep")
    );

    Inputmask({ mask: "99.999.999/9999-99" }, { autoUnmask: true }).mask(
      document.getElementById("inputCnpj")
    );

    Inputmask({ mask: "(99) 9999 9999" }, { autoUnmask: true }).mask(
      document.getElementById("inputTelefone")
    );

    const usersDatabase = LocalStorageObject.users.get();
    if (usersDatabase) {
      setUsers(usersDatabase);
    }

    _loadCities();
  }, []);

  async function _loadCities() {
    try {
      setLoading(true);
      const response = await getAllCities();
      setCities(response);
    } catch (error) {
    } finally {
      setLoading(false);
      _loadUsers();
    }
  }

  async function _loadUsers() {
    try {
      setLoading(true);
      const response = await getAll();
      LocalStorageObject.users.set(response);
      setUsers(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      let nome_balada = name.trim();
      let cnpjUnmasked = Inputmask.unmask(cnpj, {
        alias: "99.999.999/9999-99",
      });
      setLoading(true);
      await createCompany({
        nome_balada,
        id_cidade: city,
        endereco_cep: cep,
        endereco_bairro: bairro,
        endereco_logradouro: logradouro,
        endereco_numero: numero,
        codigo_ativacao_pagseguro: codigoAtivacao,
        email_admin: emailAdmin,

        enotas_nome_fantasia: nome_balada,
        endereco_complemento: complemento,
        enotas_end_complemento: complemento,
        enotas_insc_municipal: inscMunicipal,
        enotas_end_logradouro: logradouro,
        enotas_end_numero: numero,
        enotas_end_bairro: bairro,
        enotas_end_nome_municipio: city,
        enotas_end_uf: estado,
        enotas_end_cep: cep,
        enotas_end_cod_ibge_cidade: codigoIbge,
        enotas_telefone_comercial: telefoneComercial,
        enotas_optante_simples: simples,
        enotas_id_empresa: idEnotas,
        enotas_razao_social: razaoSocial,
        enotas_cnpj: cnpjUnmasked,
        enotas_ie: inscEstadual,
        pagseguro_email: emailPagseguro,
      });
      history.goBack();
    } catch (error) {
      console.log("error", error);
      if (error.data.message === "user_not_permited") {
        alert.error("Usuário não tem permissão para criar novas empresas");
      }
      if (error.data.message === "user_admin_not_found") {
        alert.error("Email do administrador não encontrado");
      }
      alert.error("Erro ao criar a nova empresa");
    } finally {
      setLoading(false);
    }
  }

  function navigateToBack() {
    history.goBack();
  }

  async function handleCep(cepValue) {
    const cepValueUnmasked = Inputmask.unmask(cepValue, { alias: "99999-999" });
    setCep(cepValueUnmasked);

    if (cepValueUnmasked.length === 8) {
      const response = await getCep(cepValueUnmasked);
      if (response.erro) {
        return alert.error("CEP não encontrado");
      }
      setBairro(response.bairro);
      setLogradouro(response.logradouro);
      setEstado(response.uf);
      setCodigoIbge(response.ibge);
    }
  }

  return (
    <Container onSubmit={handleSubmit}>
      <PageContainer>
        <PageHeader>
          <PageTitle>Adicionar empresa</PageTitle>
          <ButtonDefault type="button" onClick={navigateToBack}>
            Voltar
          </ButtonDefault>
          {(() => {
            if (loading) {
              return (
                <ButtonDefault
                  type="button"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  <FeatherIcon
                    className="App-icon"
                    icon="refresh-cw"
                    size={14}
                  />
                </ButtonDefault>
              );
            }
            return (
              <ButtonSuccess
                type="submit"
                style={{ marginLeft: 20, width: 80 }}
              >
                Salvar
              </ButtonSuccess>
            );
          })()}
        </PageHeader>
        <ColumnLayout>
          <LayoutCell>
            <br />
            <LabelText>
              Nome da empresa <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setName(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              CEP <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              id="inputCep"
              onChange={(event) => handleCep(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Logradouro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setLogradouro(event.target.value)}
              required={true}
              value={logradouro}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Bairro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setBairro(event.target.value)}
              required={true}
              value={bairro}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Número <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setNumero(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Complemento <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setComplemento(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Cidade <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              onChange={(event) => setCity(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              {cities.map((city) => {
                return (
                  <option key={city.id} value={city.id}>
                    {city.descricao}
                  </option>
                );
              })}
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Estado <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              onChange={(event) => setEstado(event.target.value)}
              required={true}
              value={estado}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Código IBGE <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setCodigoIbge(event.target.value)}
              value={codigoIbge}
              required={true}
              disabled={true}
              style={{ marginTop: 10, backgroundColor: "#999", color: "#fff" }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Dados Pagseguro</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Código ativação <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setCodigoAtivacao(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Email Pagseguro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setEmailPagseguro(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Dados Fiscais</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Razão Social</LabelText>
            <InputText
              onChange={(event) => setRazaoSocial(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>ID Enotas</LabelText>
            <InputText
              onChange={(event) => setIdEnotas(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>

          <LayoutCell>
            <LabelText>CNPJ</LabelText>
            <InputText
              id="inputCnpj"
              onChange={(event) => setCnpj(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Insc. Estadual</LabelText>
            <InputText
              onChange={(event) => setInscEstadual(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Insc. Municipal</LabelText>
            <InputText
              onChange={(event) => setInscMunicipal(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Telefone Comercial</LabelText>
            <InputText
              id="inputTelefone"
              onChange={(event) => setTelefoneComercial(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Optante Simples</LabelText>
            <SelectInput
              onChange={(event) => setSimples(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            >
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Usuário Administrador</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Email <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              onChange={(event) => setEmailAdmin(event.target.value)}
              required={true}
              value={emailAdmin}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              {(() => {
                return users.map((user) => {
                  return <option value={user.email}>{user.email}</option>;
                });
              })()}
            </SelectInput>
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <br />
        </ColumnLayout>
      </PageContainer>
      {loading && <LoadBar />}
    </Container>
  );
};
export default CompanyAdd;
