/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { confirmAlert } from "react-confirm-alert";
import { useHistory } from "react-router-dom";
import Datatable from "../../../../components/datatable";
import LoadBar from "../../../../components/loadBar";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../global/styles";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { checkoutUser, getAll } from "../../../../providers/user";
import { Container } from "./styles";

const UserList = () => {
  const history = useHistory();
  const alert = useAlert();

  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const response = LocalStorageObject.users.get();
    if (response) {
      setUsers(response);
    }
    _reloadUsers();
  }, []);

  async function _reloadUsers() {
    try {
      setLoading(true);
      const data = await getAll();
      if (data) {
        LocalStorageObject.users.set(data);
        setUsers(data);
      }
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  const columns = useMemo(() => [
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "CPF",
      accessor: "cpf",
    },
    {
      Header: "Data nascimento",
      accessor: "data_nasc",
    },
    {
      Header: "Função",
      accessor: "perfil_usuario",
    },
    {
      Header: "Empresa",
      accessor: "nome_balada",
    },
    {
      Header: "Ações",
      accessor: "",
    },
  ]);

  function navigateToAdd() {
    history.push("/general/user/add");
  }

  function editFunction(id) {
    history.push("/general/user/edit/" + id);
  }

  async function remove(qrCode) {
    try {
      setLoading(true);

      const result = await checkoutUser(qrCode);
      if (!result) throw new Error("Erro ao remover a função do usuário");
    } catch (error) {
      alert.error(error.message);
    } finally {
      _reloadUsers();
      setLoading(false);
    }
  }

  function removeFunction(qrCode) {
    confirmAlert({
      title: "Confirmar remoção da função",
      message: "Deseja realmente remover a função desse usuário?",
      buttons: [
        {
          label: "Confirmar",
          onClick: () => {
            remove(qrCode);
          },
        },
        {
          label: "Cancelar",
          onClick: () => {},
        },
      ],
    });
  }

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Usuários</PageTitle>
            <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
          </PageHeader>
          <Datatable
            columns={columns}
            data={users}
            removeFunction={removeFunction}
            editFunction={editFunction}
          />
          <br />
          <br />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default UserList;
