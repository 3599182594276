/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import LoadBar from "../../../../../components/loadBar";
import VerifyCurrentUser from "../../../../../components/verifyCurrentUser";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
} from "../../../../../global/styles";
import {
  editCategory,
  getById,
} from "../../../../../providers/productCategory";
import { Container } from "./styles";

const ProductCategoryEdit = () => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");

  const toast = useAlert();
  const history = useHistory();
  let { id } = useParams();

  useEffect(() => {
    _loadCategory();
  }, []);

  function navigateToBack() {
    history.goBack();
  }

  function handleSubmit(e) {
    e.preventDefault();
    _saveCategory();
  }

  async function _loadCategory() {
    try {
      setLoading(true);
      const response = await getById(id);
      if (!response) throw new Error("");
      setName(response.descricao);
    } catch (error) {
      history.goBack();
    } finally {
      setLoading(false);
    }
  }

  async function _saveCategory() {
    try {
      setLoading(true);
      const response = await editCategory(id, name);
      if (!response) throw new Error("Erro ao salvar a nova categoria");
      history.goBack();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <PageContainer>
          <PageHeader>
            <PageTitle>Editar categoria de produto</PageTitle>
            <ButtonDefault type="button" onClick={navigateToBack}>
              Voltar
            </ButtonDefault>
            {(() => {
              if (loading) {
                return (
                  <ButtonDefault
                    type="button"
                    style={{ marginLeft: 20, width: 80 }}
                  >
                    <FeatherIcon
                      className="App-icon"
                      icon="refresh-cw"
                      size={14}
                    />
                  </ButtonDefault>
                );
              }
              return (
                <ButtonSuccess
                  type="submit"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  Salvar
                </ButtonSuccess>
              );
            })()}
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Nome da categoria <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setName(event.target.value)}
                required={true}
                value={name}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
            <LayoutCell />
            <LayoutCell />
          </ColumnLayout>
        </PageContainer>
        {loading && <LoadBar />}
      </Container>
      <VerifyCurrentUser />
    </>
  );
};
export default ProductCategoryEdit;
