import moment from "moment";
import React, { useEffect, useState } from "react";
import { TdName, TdNumber, TdStatus, Th, ThNumber } from "./styles";

function ReportSalesTransfer({ rawData }) {
  useEffect(() => {
    _processData(rawData);
  }, [rawData]);

  const [sales, setSales] = useState([]);

  function _processData(saleData) {
    if (!saleData) return;

    let formatter = new Intl.NumberFormat([], {
      style: "currency",
      currency: "BRL",
    });

    let salesArray = saleData.map((sale) => {
      sale.data_venda_ajuste = moment(sale.data_venda_ajuste).format(
        "DD/MM/YYYY"
      );

      sale.saleValue = formatter.format(sale.valor_original_transacao);
      const pagseguroValue =
        sale.valor_original_transacao - sale.valor_liquido_transacao;
      const bimoreValue = sale.valor_liquido_transacao - sale.valor_transferido;
      const liquidValue = sale.valor_liquido_transacao - bimoreValue;
      sale.pagseguroTax = formatter.format(pagseguroValue);
      sale.bimoreTax = formatter.format(bimoreValue);
      sale.saleLiquid = formatter.format(liquidValue);
      sale.data_transferencia = moment(sale.data_transferencia).format(
        "DD/MM/YYYY"
      );
      if (sale.transferencia_confirmada) {
        sale.status = "ok";
      } else {
        sale.status = "pendente";
      }
      return sale;
    });
    console.log(salesArray);
    setSales(salesArray);
  }

  if (sales.length < 1) return <></>;

  return (
    <table width="100%">
      <thead>
        <tr>
          <Th>Cód Venda</Th>
          <Th>Leitor</Th>
          <Th>Data venda</Th>
          <ThNumber>Valor Venda</ThNumber>
          <ThNumber>Taxa Pagseguro</ThNumber>
          <ThNumber>Taxa Bimore</ThNumber>
          <ThNumber>Líquido</ThNumber>
          <Th>Data transf.</Th>
          <Th>Status transf.</Th>
        </tr>
      </thead>
      <tbody>
        {sales.map((sale) => {
          return (
            <tr key={sale.id.toString()}>
              <TdName>{sale.codigo_venda}</TdName>
              <TdName>{sale.numero_serie_leitor}</TdName>
              <TdName>{sale.data_venda_ajuste}</TdName>
              <TdNumber>{sale.saleValue}</TdNumber>
              <TdNumber>{sale.pagseguroTax}</TdNumber>
              <TdNumber>{sale.bimoreTax}</TdNumber>
              <TdNumber>{sale.saleLiquid}</TdNumber>
              <TdStatus>{sale.data_transferencia}</TdStatus>
              <TdStatus>{sale.status}</TdStatus>
            </tr>
          );
        })}
      </tbody>
    </table>
  );
}

export default ReportSalesTransfer;
