import styled from "styled-components";
import theme from "../../global/theme";

export const Container = styled.div`
  position: fixed;
  top: 0px;
  width: 100%;
  height: 5px;
  background-color: ${theme.defaultBackgroundColor};
  z-index: 200;
`;

export const Bar = styled.div`
  width: 40%;
  height: 5px;
  left: -40%;
  background-color: ${theme.textColor};
  position: absolute;
`;
