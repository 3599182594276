const primaryColor = "#784DD7";
const darkColor = "#593A9E";
const primaryLightColor = "#c8bee2";
const successColor = "#00aa33";
const successDarkColor = "#047a28";
const dangerColor = "#d12727";
const dangerDarkColor = "#932c2c";
const defaultBackgroundColor = "#EEEEEE";
const lightColor = "#FFF";
const textColor = "#444";
const defaultColor = "#444";
const defaultDarkColor = "#222";

export default {
  primaryColor,
  primaryLightColor,
  successColor,
  successDarkColor,
  dangerColor,
  dangerDarkColor,
  defaultBackgroundColor,
  lightColor,
  textColor,
  darkColor,
  defaultColor,
  defaultDarkColor,
};
