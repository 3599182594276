import styled from "styled-components";
import { ButtonDanger, ButtonPrimary } from "../../global/styles";
import theme from "../../global/theme";

export const Table = styled.table`
  width: 100%;
  margin-top: 20px;
  border-spacing: 0px;
`;

export const Thead = styled.thead``;

export const Th = styled.th`
  padding: 10px;
  background-color: ${theme.primaryColor};
  color: ${theme.lightColor};
  text-align: left;
`;

export const Tr = styled.tr``;

export const Td = styled.td`
  padding: 10px;
  border: 1px solid ${theme.defaultBackgroundColor};
  text-align: left;
  font-size: 13px;
`;

export const ButtonEdit = styled(ButtonPrimary)`
  font-size: 12px;
`;
export const ButtonRemove = styled(ButtonDanger)`
  font-size: 12px;
`;
