/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Select from "react-select";
import LoadBar from "../../../components/loadBar";
import ReportStock from "../../../components/reportStock";
import {
  ButtonPrimary,
  ColumnLayout,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../global/styles";
import LocalStorageObject from "../../../providers/localStorageObject";
import { getStockQuantity } from "../../../providers/reports";
import { Container } from "./styles";
const { useState } = require("react");

const StockReport = () => {
  const [loading, setLoading] = useState(false);
  const [filterCompanyIds, setFilterCompanyIds] = useState([]);
  const [rawData, setRawData] = useState([]);

  useEffect(() => {
    _loadCompanies();
  }, []);

  function handleClickFilter() {
    console.log("filterCompanyIds", filterCompanyIds);
    _loadReport(filterCompanyIds);
  }

  async function _loadCompanies() {
    const companiesData = LocalStorageObject.companies.get();
    if (!companiesData) return;
    let companiesOptions = [];
    companiesOptions.push({ value: "", label: "Todas" });
    companiesData.map((company) => {
      companiesOptions.push({
        value: company.id,
        label: company.nome_balada,
      });
      return true;
    });
    _loadReport([]);
  }

  async function _loadReport(companiesIds) {
    try {
      setLoading(true);
      const response = await getStockQuantity(companiesIds);
      setRawData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  let allCompanyIds = [];

  function handleChangeCompanies(value) {
    if (value) {
      let todas = false;
      let ids = [];
      value.map((id) => {
        if (id.value === "") todas = true;
        return true;
      });
      if (todas) {
        allCompanyIds.map((company) => {
          ids.push(company.id);
          return true;
        });
      } else {
        ids = value.map((id) => id.value);
      }

      setFilterCompanyIds(ids);
    } else {
      setFilterCompanyIds([]);
    }
  }

  let companyIds = [
    {
      value: "",
      label: "Todas",
    },
  ];

  if (LocalStorageObject.companies.get()) {
    allCompanyIds = LocalStorageObject.companies.get().map((company) => {
      companyIds.push({
        value: company.id,
        label: company.nome_balada,
      });
      return company;
    });
  }

  const companiesOptions = companyIds;

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Estoque</PageTitle>
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <Select
                options={companiesOptions}
                isMulti={true}
                isSearchable={true}
                onChange={handleChangeCompanies}
                placeholder="Selecione a empresa..."
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ButtonPrimary onClick={handleClickFilter}>Filtrar</ButtonPrimary>
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ReportStock rawData={rawData} />
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      {loading && <LoadBar />}
    </>
  );
};

export default StockReport;
