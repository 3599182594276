import React from "react";
import { useHistory } from "react-router-dom";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../global/styles";
import { Container } from "./styles";

const OwnerProductList = () => {
  const history = useHistory();

  function navigateToAdd() {
    history.push("/general/product/add");
  }

  return (
    <Container>
      <PageContainer>
        <PageHeader>
          <PageTitle>Produtos</PageTitle>
          <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
        </PageHeader>
      </PageContainer>
    </Container>
  );
};

export default OwnerProductList;
