import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import {
  ButtonDefault,
  ButtonSuccess,
  InputText,
  LayoutCell,
} from "../../global/styles";
import { getAddress } from "../../providers/events";
import { AddressItem } from "./styles";

const SearchAddress = ({ setState, setCity, setLatitude, setLongitude }) => {
  const [address, setAddress] = useState("");
  const [loading, setLoading] = useState(false);
  const [foundAddresses, setFoundAddresses] = useState([]);

  const toast = useAlert();

  async function handleSearchAddress() {
    try {
      setLoading(true);
      const response = await getAddress(address);
      if (response.length < 1) {
        toast.error("Nenhum endereço encontrado, tente novamente");
      }
      setFoundAddresses(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function handleSetAddress(addressItem) {
    setFoundAddresses([]);
    const { state, town, city } = addressItem.components;
    const { lat, lng } = addressItem.geometry;
    setState(state);
    setCity(city ? city : town);
    setLatitude(lat);
    setLongitude(lng);
    setAddress(addressItem.formatted);
  }

  return (
    <>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <InputText
          onChange={(event) => setAddress(event.target.value)}
          value={address}
          required={true}
          style={{ marginTop: 10, width: "85%" }}
          placeholder="Av São Paulo"
        />
        {(() => {
          if (loading) {
            return (
              <ButtonDefault
                style={{ marginTop: 10, width: "10%" }}
                type="button"
                onClick={handleSearchAddress}
              >
                <FeatherIcon className="App-icon" icon="refresh-cw" size={14} />
              </ButtonDefault>
            );
          } else {
            return (
              <ButtonSuccess
                style={{ marginTop: 10, width: "10%" }}
                type="button"
                onClick={handleSearchAddress}
              >
                Buscar
              </ButtonSuccess>
            );
          }
        })()}
      </div>
      <LayoutCell style={{ marginRight: -10, marginLeft: -10, marginTop: -10 }}>
        {foundAddresses.map((addressItem) => {
          return (
            <AddressItem
              key={addressItem.formatted}
              onClick={() => {
                handleSetAddress(addressItem);
              }}
            >
              {addressItem.formatted}
            </AddressItem>
          );
        })}
      </LayoutCell>
    </>
  );
};
export default SearchAddress;
