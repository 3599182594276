/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import moment from "moment";
import React, { useEffect } from "react";
import { useAlert } from "react-alert";
import Select from "react-select";
import LoadBar from "../../../components/loadBar";
import ReportSalesTransfer from "../../../components/reportSalesTransfer";
import {
  ButtonDefault,
  ButtonPrimary,
  ColumnLayout,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../global/styles";
import LocalStorageObject from "../../../providers/localStorageObject";
import { getSalesTransfer } from "../../../providers/reports";
import { DateSelect } from "../../owner/shop/events/eventAdd/styles";
import { Container } from "./styles";
const { useState } = require("react");

const SalesTransferReport = () => {
  const [loading, setLoading] = useState(false);
  const [filterCompanyId, setFilterCompanyId] = useState("");
  const [rawData, setRawData] = useState([]);
  const [filterStartDate, setFilterStartDate] = useState(
    moment().startOf("day").format("YYYY-MM-DD HH:mm:ss")
  );
  const [filterEndDate, setFilterEndDate] = useState(
    moment().endOf("day").format("YYYY-MM-DD HH:mm:ss")
  );

  const alert = useAlert();

  useEffect(() => {
    _loadCompanies();
  }, []);

  function handleClickFilter() {
    if (!filterCompanyId) return alert.error("Escolha a empresa");
    if (!filterStartDate) return alert.error("Escolha a data inicial");
    if (!filterEndDate) return alert.error("Escolha a data final");
    _loadReport();
  }

  async function _loadCompanies() {
    const companiesData = LocalStorageObject.companies.get();
    if (!companiesData) return;
    let companiesOptions = [];
    companiesData.map((company) => {
      companiesOptions.push({
        value: company.id,
        label: company.nome_balada,
      });
      return true;
    });
  }

  async function _loadReport() {
    try {
      setLoading(true);
      const response = await getSalesTransfer(
        filterCompanyId,
        filterStartDate,
        filterEndDate
      );
      setRawData(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  // eslint-disable-next-line no-unused-vars
  let allCompanyIds = [];

  function handleChangeCompanies(option) {
    setFilterCompanyId(option.value);
  }

  let companyIds = [];

  if (LocalStorageObject.companies.get()) {
    allCompanyIds = LocalStorageObject.companies.get().map((company) => {
      companyIds.push({
        value: company.id,
        label: company.nome_balada,
      });
      return company;
    });
  }

  const companiesOptions = companyIds;

  return (
    <>
      <Container>
        <PageContainer className="printable">
          <PageHeader>
            <PageTitle>Transferências Pagseguro</PageTitle>
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <Select
                options={companiesOptions}
                isMulti={false}
                isSearchable={true}
                onChange={handleChangeCompanies}
                placeholder="Selecione a empresa..."
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>Data início</LabelText>
              <DateSelect
                onChange={(event) => {
                  setFilterStartDate(event.format("YYYY-MM-DD HH:mm:ss"));
                }}
                locale="pt-br"
                closeOnSelect={true}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                defaultValue={moment().startOf("day")}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data final</LabelText>
              <DateSelect
                onChange={(event) => {
                  setFilterEndDate(event.format("YYYY-MM-DD HH:mm:ss"));
                }}
                locale="pt-br"
                closeOnSelect={true}
                dateFormat="DD/MM/YYYY"
                timeFormat="HH:mm"
                defaultValue={moment().endOf("day")}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ButtonPrimary onClick={handleClickFilter}>Filtrar</ButtonPrimary>
            </LayoutCell>
            <LayoutCell>
              <ButtonDefault
                onClick={() => {
                  window.print();
                }}
              >
                Imprimir
              </ButtonDefault>
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <ReportSalesTransfer rawData={rawData} />
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      {loading && <LoadBar />}
    </>
  );
};

export default SalesTransferReport;
