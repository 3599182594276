import React from "react";
import { Route } from "react-router-dom";
import SideMenu from "../components/sideMenu";
import TopMenu from "../components/topMenu";
import ClientList from "../pages/generalOwner/clients/clientList";
import CompanyAdd from "../pages/generalOwner/companies/companyAdd";
import CompanyEdit from "../pages/generalOwner/companies/companyEdit";
import CompanyList from "../pages/generalOwner/companies/companyList";
import GeneralHome from "../pages/generalOwner/generalHome";
import OwnerProductAdd from "../pages/generalOwner/ownerProducts/ownerProductAdd";
import OwnerProductList from "../pages/generalOwner/ownerProducts/ownerProductList";
import SalesTransferReport from "../pages/generalOwner/salesTransferReport";
import StockReport from "../pages/generalOwner/stockReport";
import TerminalAdd from "../pages/generalOwner/terminals/terminalAdd";
import TerminalEdit from "../pages/generalOwner/terminals/terminalEdit";
import TerminalList from "../pages/generalOwner/terminals/terminalList";
import ChangeFunction from "../pages/generalOwner/users/changeFunction";
import UserAdd from "../pages/generalOwner/users/userAdd";
import UserList from "../pages/generalOwner/users/userList";
import Home from "../pages/owner/home";
import EventAdd from "../pages/owner/shop/events/eventAdd";
import EventList from "../pages/owner/shop/events/eventList";
import ProductCategoryAdd from "../pages/owner/shop/productCategory/productCategoryAdd";
import ProductCategoryEdit from "../pages/owner/shop/productCategory/productCategoryEdit";
import ProductCategoryList from "../pages/owner/shop/productCategory/productCategoryList";
import ProductList from "../pages/owner/shop/products/productList";

const OwnerRoutes = () => {
  return (
    <>
      <TopMenu />
      <SideMenu />
      <Route path="/event/add">
        <EventAdd />
      </Route>
      <Route path="/event/list">
        <EventList />
      </Route>
      <Route path="/product/category/list">
        <ProductCategoryList />
      </Route>
      <Route path="/product/category/add">
        <ProductCategoryAdd />
      </Route>
      <Route path="/product/category/edit/:id">
        <ProductCategoryEdit />
      </Route>
      <Route path="/product/list">
        <ProductList />
      </Route>
      <Route path="/company/list">
        <CompanyList />
      </Route>
      <Route path="/company/add">
        <CompanyAdd />
      </Route>
      <Route path="/company/edit/:id">
        <CompanyEdit />
      </Route>
      <Route path="/" exact>
        <Home />
      </Route>
      <Route path="/stock/report" exact>
        <StockReport />
      </Route>
      <Route path="/general/home" exact>
        <GeneralHome />
      </Route>
      <Route path="/general/product/list" exact>
        <OwnerProductList />
      </Route>
      <Route path="/general/product/add" exact>
        <OwnerProductAdd />
      </Route>
      <Route path="/general/terminals/list" exact>
        <TerminalList />
      </Route>
      <Route path="/general/terminals/add" exact>
        <TerminalAdd />
      </Route>
      <Route path="/general/terminals/edit/:id" exact>
        <TerminalEdit />
      </Route>
      <Route path="/general/user/list" exact>
        <UserList />
      </Route>
      <Route path="/general/user/add" exact>
        <UserAdd />
      </Route>
      <Route path="/general/user/edit/:id" exact>
        <ChangeFunction />
      </Route>
      <Route path="/general/sales/transfer/report" exact>
        <SalesTransferReport />
      </Route>
      <Route path="/general/client/list" exact>
        <ClientList />
      </Route>
    </>
  );
};
export default OwnerRoutes;
