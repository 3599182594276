/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import Inputmask from "inputmask";
import QRCode from "qrcode.react";
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory, useParams } from "react-router-dom";
import LoadBar from "../../../../components/loadBar";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
  SelectInput,
} from "../../../../global/styles";
import { getCep } from "../../../../providers/cep";
import { getAllCities } from "../../../../providers/city";
import {
  getCompanyById,
  sendCompanyQrcode,
  updateCompany,
} from "../../../../providers/company";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { get, getAll } from "../../../../providers/user";
import { Container } from "./styles";

const CompanyEdit = () => {
  const [company, setCompany] = useState({});

  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [cities, setCities] = useState([]);
  const [city, setCity] = useState("");
  const [cep, setCep] = useState("");
  const [logradouro, setLogradouro] = useState("");
  const [bairro, setBairro] = useState("");
  const [numero, setNumero] = useState("");
  const [codigoAtivacao, setCodigoAtivacao] = useState("");
  const [idEnotas, setIdEnotas] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [inscMunicipal, setInscMunicipal] = useState("");
  const [inscEstadual, setInscEstadual] = useState("");
  const [telefoneComercial, setTelefoneComercial] = useState("");
  const [simples, setSimples] = useState("");
  const [complemento, setComplemento] = useState("");
  const [emailPagseguro, setEmailPagseguro] = useState("");
  const [estado, setEstado] = useState("");
  const [codigoIbge, setCodigoIbge] = useState("");
  const [emailAdmin, setEmailAdmin] = useState("");
  const [emissao, setEmissao] = useState("");
  const [ambiente, setAmbiente] = useState("");
  const [tipo, setTipo] = useState("");
  const [sendEmail, setSendEmail] = useState("");
  const [qrCodeView, setQrCodeView] = useState(false);
  const [caixaEmail, setCaixaEmail] = useState("");
  const [caixaToken, setCaixaToken] = useState("");
  const [users, setUsers] = useState([]);
  const [sellers, setsellers] = useState([]);
  const [seller, setSeller] = useState("");

  const history = useHistory();
  const alert = useAlert();
  const { id } = useParams();

  useEffect(() => {
    Inputmask({ mask: "99999-999" }, { autoUnmask: true }).mask(
      document.getElementById("inputCep")
    );

    Inputmask({ mask: "99.999.999/9999-99" }, { autoUnmask: true }).mask(
      document.getElementById("inputCnpj")
    );

    Inputmask({ mask: "(99) 9999 9999" }, { autoUnmask: true }).mask(
      document.getElementById("inputTelefone")
    );

    _loadCities();
  }, []);

  async function _loadCities() {
    try {
      setLoading(true);
      const response = await getAllCities();
      setCities(response);
    } catch (error) {
    } finally {
      _reloadUsers();
    }
  }

  async function _reloadUsers() {
    try {
      setLoading(true);
      const data = await getAll();
      LocalStorageObject.users.set(data);
      setUsers(data);

      let sellersData = [];

      data.map((user) => {
        if (
          user.id_perfil_usuario === 10 &&
          user.id_balada_atual === parseInt(id)
        ) {
          sellersData.push(user);
        }
        return true;
      });

      setsellers(sellersData);
    } catch (error) {
    } finally {
      setLoading(false);
      _loadBalada();
    }
  }

  async function _loadBalada() {
    try {
      setLoading(true);
      const response = await getCompanyById({ id });
      if (!response) history.goBack();
      console.log("response", response);
      const {
        nome_balada,
        endereco_cep,
        endereco_bairro,
        endereco_logradouro,
        endereco_numero,
        id_cidade,
        usuario_admin,
        codigo_ativacao_pagseguro,
        endereco_complemento,
        enotas_end_uf,
        enotas_cnpj,
        enotas_end_cod_ibge_cidade,
        pagseguro_email,
        enotas_razao_social,
        id_enotas,
        enotas_id_empresa,
        enotas_ie,
        enotas_insc_municipal,
        enotas_telefone_comercial,
        enotas_optante_simples,
        caixa_email,
        caixa_token,
      } = response;
      setName(nome_balada);
      setCep(endereco_cep);
      setLogradouro(endereco_logradouro);
      setComplemento(endereco_complemento);
      setBairro(endereco_bairro);
      setNumero(endereco_numero);
      setCity(id_cidade);
      setEstado(enotas_end_uf);
      setEmailAdmin(usuario_admin);
      setCodigoAtivacao(codigo_ativacao_pagseguro);
      setCodigoIbge(enotas_end_cod_ibge_cidade);
      setEmailPagseguro(pagseguro_email);
      setRazaoSocial(enotas_razao_social);
      setIdEnotas(enotas_id_empresa);
      setCnpj(enotas_cnpj);
      setInscEstadual(enotas_ie);
      setInscMunicipal(enotas_insc_municipal);
      setTelefoneComercial(enotas_telefone_comercial);
      setSimples(enotas_optante_simples);
      setCompany({
        id,
        nome_balada,
      });
    } catch (error) {
      history.goBack();
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmit(e, noBack = false) {
    e.preventDefault();
    try {
      let nome_balada = name.trim();
      let cnpjUnmasked = Inputmask.unmask(cnpj, {
        alias: "99.999.999/9999-99",
      });
      setLoading(true);
      await updateCompany({
        id,
        nome_balada,
        id_cidade: city,
        endereco_cep: cep,
        endereco_bairro: bairro,
        endereco_logradouro: logradouro,
        endereco_numero: numero,
        codigo_ativacao_pagseguro: codigoAtivacao,
        email_admin: emailAdmin,
        enotas_nome_fantasia: nome_balada,
        endereco_complemento: complemento,
        enotas_end_complemento: complemento,
        enotas_insc_municipal: inscMunicipal,
        enotas_end_logradouro: logradouro,
        enotas_end_numero: numero,
        enotas_end_bairro: bairro,
        enotas_end_nome_municipio: city,
        enotas_end_uf: estado,
        enotas_end_cep: cep,
        enotas_end_cod_ibge_cidade: codigoIbge,
        enotas_telefone_comercial: telefoneComercial,
        enotas_optante_simples: simples,
        enotas_id_empresa: idEnotas,
        enotas_razao_social: razaoSocial,
        enotas_cnpj: cnpjUnmasked,
        enotas_ie: inscEstadual,
        pagseguro_email: emailPagseguro,
      });
      if (noBack) {
        generateQrCode();
      } else {
        history.goBack();
      }
    } catch (error) {
      if (error) {
        if (error.data) {
          if (error.data.message === "user_not_permited")
            alert.error("Usuário não tem permissão para editar empresas");
          if (error.data.message === "user_admin_not_found")
            alert.error("Email do administrador não encontrado");
        } else {
          alert.error("Erro ao editar a empresa");
        }
      }

      alert.error("Erro ao editar a empresa");
    } finally {
      setLoading(false);
    }
  }

  async function generateQrCode() {
    if (!emissao) return alert.error('Escolha a opção em "Habilitar emissão"');
    if (!ambiente)
      return alert.error('Escolha a opção em "Ambiente de emissão"');
    if (!seller) return alert.error("Informe o usuário vendedor");
    if (!tipo) return alert.error("Informe o tipo de nota fiscal");

    try {
      setLoading(true);
      const result = await get(seller);
      if (!result) throw new Error("Erro ao carregar o usuário vendedor");
      setCaixaEmail(result.email);
      setCaixaToken(result.token_login);
    } catch (error) {
      alert.error(error.message);
    } finally {
      setLoading(false);
    }

    if (sendEmail) {
      setLoading(true);
      try {
        const response = await sendCompanyQrcode(
          "Qrcode para configuração do terminal",
          sendEmail,
          {
            id,
            efetuarEmissao: emissao,
            email: caixaEmail,
            token: caixaToken,
            serieNota: "2",
            proximaNota: "1",
            ambienteFiscal: ambiente,
            tipo,
          },
          "Utilize o Qrcode no anexo para configurar o terminal"
        );
        console.log("response", response);
      } catch (error) {
      } finally {
        setLoading(false);
      }
    }

    setTimeout(() => {
      setQrCodeView(true);
    }, 400);
  }

  function navigateToBack() {
    history.goBack();
  }

  async function handleCep(cepValue) {
    const cepValueUnmasked = Inputmask.unmask(cepValue, { alias: "99999-999" });
    setCep(cepValueUnmasked);

    if (cepValueUnmasked.length === 8) {
      const response = await getCep(cepValueUnmasked);
      if (response.erro) {
        return alert.error("CEP não encontrado");
      }
      setBairro(response.bairro);
      setLogradouro(response.logradouro);
      setEstado(response.uf);
      setCodigoIbge(response.ibge);
    }
  }

  return (
    <Container onSubmit={handleSubmit}>
      <PageContainer>
        <PageHeader>
          <PageTitle>Editar empresa</PageTitle>
          <ButtonDefault type="button" onClick={navigateToBack}>
            Voltar
          </ButtonDefault>
          {(() => {
            if (loading) {
              return (
                <ButtonDefault
                  type="button"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  <FeatherIcon
                    className="App-icon"
                    icon="refresh-cw"
                    size={14}
                  />
                </ButtonDefault>
              );
            }
            return (
              <ButtonSuccess
                type="submit"
                style={{ marginLeft: 20, width: 80 }}
              >
                Salvar
              </ButtonSuccess>
            );
          })()}
        </PageHeader>
        <ColumnLayout>
          <LayoutCell>
            <br />
            <LabelText>
              Nome da empresa <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setName(event.target.value)}
              required={true}
              value={name}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              CEP <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              id="inputCep"
              value={cep}
              onChange={(event) => handleCep(event.target.value)}
              required={true}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Logradouro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setLogradouro(event.target.value)}
              required={true}
              value={logradouro}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Bairro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setBairro(event.target.value)}
              required={true}
              value={bairro}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Número <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setNumero(event.target.value)}
              required={true}
              value={numero}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Complemento <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setComplemento(event.target.value)}
              required={true}
              value={complemento}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Cidade <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              onChange={(event) => setCity(event.target.value)}
              required={true}
              value={city}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              {cities.map((city) => {
                return (
                  <option key={city.id} value={city.id}>
                    {city.descricao}
                  </option>
                );
              })}
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Estado <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              onChange={(event) => setEstado(event.target.value)}
              required={true}
              value={estado}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              <option value="AC">Acre</option>
              <option value="AL">Alagoas</option>
              <option value="AP">Amapá</option>
              <option value="AM">Amazonas</option>
              <option value="BA">Bahia</option>
              <option value="CE">Ceará</option>
              <option value="DF">Distrito Federal</option>
              <option value="ES">Espírito Santo</option>
              <option value="GO">Goiás</option>
              <option value="MA">Maranhão</option>
              <option value="MT">Mato Grosso</option>
              <option value="MS">Mato Grosso do Sul</option>
              <option value="MG">Minas Gerais</option>
              <option value="PA">Pará</option>
              <option value="PB">Paraíba</option>
              <option value="PR">Paraná</option>
              <option value="PE">Pernambuco</option>
              <option value="PI">Piauí</option>
              <option value="RJ">Rio de Janeiro</option>
              <option value="RN">Rio Grande do Norte</option>
              <option value="RS">Rio Grande do Sul</option>
              <option value="RO">Rondônia</option>
              <option value="RR">Roraima</option>
              <option value="SC">Santa Catarina</option>
              <option value="SP">São Paulo</option>
              <option value="SE">Sergipe</option>
              <option value="TO">Tocantins</option>
              <option value="EX">Estrangeiro</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Código IBGE <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setCodigoIbge(event.target.value)}
              value={codigoIbge}
              required={true}
              disabled={true}
              style={{ marginTop: 10, backgroundColor: "#999", color: "#fff" }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Dados Pagseguro</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Código ativação <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setCodigoAtivacao(event.target.value)}
              required={true}
              value={codigoAtivacao}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Email Pagseguro <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <InputText
              onChange={(event) => setEmailPagseguro(event.target.value)}
              required={true}
              value={emailPagseguro}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Dados Fiscais</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Razão Social</LabelText>
            <InputText
              onChange={(event) => setRazaoSocial(event.target.value)}
              value={razaoSocial}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>ID Enotas</LabelText>
            <InputText
              onChange={(event) => setIdEnotas(event.target.value)}
              style={{ marginTop: 10 }}
              value={idEnotas}
            />
          </LayoutCell>

          <LayoutCell>
            <LabelText>CNPJ</LabelText>
            <InputText
              id="inputCnpj"
              value={cnpj}
              onChange={(event) =>
                setCnpj(
                  Inputmask.unmask(event.target.value, {
                    alias: "99.999.999/9999-99",
                  })
                )
              }
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Insc. Estadual</LabelText>
            <InputText
              onChange={(event) => setInscEstadual(event.target.value)}
              style={{ marginTop: 10 }}
              value={inscEstadual}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Insc. Municipal</LabelText>
            <InputText
              onChange={(event) => setInscMunicipal(event.target.value)}
              value={inscMunicipal}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Telefone Comercial</LabelText>
            <InputText
              id="inputTelefone"
              value={telefoneComercial}
              onChange={(event) => setTelefoneComercial(event.target.value)}
              style={{ marginTop: 10 }}
            />
          </LayoutCell>
          <LayoutCell>
            <LabelText>Optante Simples</LabelText>
            <SelectInput
              onChange={(event) => setCity(event.target.value)}
              required={true}
              value={simples}
              style={{ marginTop: 10 }}
            >
              <option value="Não">Não</option>
              <option value="Sim">Sim</option>
            </SelectInput>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Usuário Administrador</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>
              Email <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              style={{ marginTop: 10 }}
              required={true}
              value={emailAdmin}
              onChange={(event) => setEmailAdmin(event.target.value)}
            >
              <option value="">Selecione</option>
              {(() => {
                return users.map((user) => {
                  return (
                    <option key={user.id.toString()} value={user.email}>
                      {user.email}
                    </option>
                  );
                });
              })()}
            </SelectInput>
          </LayoutCell>
          <LayoutCell />
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <h4>Configuração do terminal</h4>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Emitir NF</LabelText>
            <SelectInput
              onChange={(event) => setEmissao(event.target.value)}
              value={emissao}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              <option value="Nao">Nao</option>
              <option value="Sim">Sim</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>Tipo NF</LabelText>
            <SelectInput
              onChange={(event) => setTipo(event.target.value)}
              value={tipo}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              <option value="nfce">NFCe</option>
              <option value="nfe">NFe</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>Ambiente</LabelText>
            <SelectInput
              onChange={(event) => setAmbiente(event.target.value)}
              value={ambiente}
              style={{ marginTop: 10 }}
            >
              <option value="">Selecione</option>
              <option value="Homologacao">Homologação</option>
              <option value="Producao">Produção</option>
            </SelectInput>
          </LayoutCell>
          <LayoutCell>
            <LabelText>
              Vendedor <RequiredLabel>Obrigatório</RequiredLabel>{" "}
            </LabelText>
            <SelectInput
              style={{ marginTop: 10 }}
              required={true}
              value={seller}
              onChange={(event) => setSeller(event.target.value)}
            >
              <option value="">Selecione</option>
              {(() => {
                return sellers.map((user) => {
                  return (
                    <option key={user.id.toString()} value={user.id}>
                      {user.email}
                    </option>
                  );
                });
              })()}
            </SelectInput>
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <LabelText>Enviar por email?</LabelText>
            <InputText
              onChange={(event) => setSendEmail(event.target.value)}
              value={sendEmail}
              placeholder="Email destinatário"
              style={{ marginTop: 10 }}
            />
            <br />
            {(() => {
              if (loading) {
                return (
                  <>
                    <ButtonDefault
                      type="button"
                      style={{ marginLeft: 20, width: 80 }}
                    >
                      <FeatherIcon
                        className="App-icon"
                        icon="refresh-cw"
                        size={14}
                      />
                    </ButtonDefault>
                  </>
                );
              } else {
                return (
                  <>
                    <ButtonSuccess
                      onClick={(e) => {
                        handleSubmit(e, true);
                      }}
                    >
                      Gerar Qrcode
                    </ButtonSuccess>
                  </>
                );
              }
            })()}
          </LayoutCell>
          <LayoutCell>
            {(() => {
              if (qrCodeView) {
                return (
                  <>
                    <QRCode
                      size={300}
                      value={JSON.stringify({
                        id,
                        efetuarEmissao: emissao,
                        email: caixaEmail,
                        token: caixaToken,
                        serieNota: "2",
                        proximaNota: "1",
                        ambienteFiscal: ambiente,
                        tipo,
                      })}
                    />
                  </>
                );
              }
            })()}
          </LayoutCell>
        </ColumnLayout>
        <ColumnLayout>
          <LayoutCell>
            <br></br>
          </LayoutCell>
        </ColumnLayout>
      </PageContainer>
      {loading && <LoadBar />}
    </Container>
  );
};
export default CompanyEdit;
