/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import LoadBar from "../../../../components/loadBar";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  RequiredLabel,
  SelectInput,
} from "../../../../global/styles";
import { getGeneralCompanies } from "../../../../providers/company";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { createTerminal } from "../../../../providers/terminal";
import { Container } from "./styles";

const TerminalAdd = () => {
  const alert = useAlert();
  const history = useHistory();

  const [serial, setSerial] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const response = LocalStorageObject.companies.get();
    setCompanies(response);
    _loadCompanies();
  }, []);

  async function _loadCompanies() {
    setLoading(true);
    try {
      const response = await getGeneralCompanies();
      setCompanies(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  async function _save(e) {
    e.preventDefault();
    setLoading(true);
    try {
      await createTerminal({ companyId: company, serial });
      history.goBack();
    } catch (error) {
      alert.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container onSubmit={_save}>
        <PageContainer>
          <PageHeader>
            <PageTitle>Adicionar terminal</PageTitle>
            <ButtonDefault
              type="button"
              onClick={() => {
                history.goBack();
              }}
              style={{ marginRight: 20, width: 80 }}
            >
              Voltar
            </ButtonDefault>
            <ButtonSuccess style={{ width: 80 }}>Salvar</ButtonSuccess>
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <br />
              <LabelText>
                Empresa<RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <SelectInput
                style={{ marginTop: 10 }}
                required={true}
                onChange={(event) => setCompany(event.target.value)}
              >
                <option value="">Selecione</option>
                {companies.map((company) => (
                  <option key={company.id.toString()} value={company.id}>
                    {company.nome_balada}
                  </option>
                ))}
              </SelectInput>
            </LayoutCell>
            <LayoutCell>
              <br />
              <LabelText>
                Nº Série Terminal<RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setSerial(Number(event.target.value))}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      {loading && <LoadBar />}
    </>
  );
};

export default TerminalAdd;
