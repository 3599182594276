import api from "../services/backend";
import microservice from "../services/microservice";
import LocalStorageObject from "./localStorageObject";

export async function getCompanies() {
  try {
    const user = LocalStorageObject.currentUser.get();

    const response = await api.post("balada/viewbyowner", {
      email: user.email,
      token: user.token_login,
    });
    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return [];
  }
}

export async function getGeneralCompanies() {
  try {
    const user = LocalStorageObject.currentUser.get();

    const response = await microservice.post("baladas/manager", {
      email: user.email,
      token: user.token_login,
    });

    return response.data;
  } catch (error) {
    return [];
  }
}

export async function sendCompanyQrcode(subject, to, qrcode, message) {
  try {
    await microservice.post("email/company/qrcode", {
      subject,
      to,
      qrcode,
      message,
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function replicationCompany(companyId) {
  try {
    const user = LocalStorageObject.currentUser.get();

    await microservice.post("replication/company/data", {
      email: user.email,
      token: user.token_login,
      companyId,
    });

    return true;
  } catch (error) {
    return false;
  }
}

export async function getCompanyById({ id }) {
  return new Promise(async (resolve, reject) => {
    const user = LocalStorageObject.currentUser.get();

    microservice
      .post("baladas/view/" + id, {
        email: user.email,
        token: user.token_login,
      })
      .then((result) => {
        resolve(result.data);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function createCompany({
  nome_balada,
  id_cidade,
  endereco_bairro,
  endereco_numero,
  endereco_logradouro,
  endereco_cep,
  pagseguro_pub_key,
  codigo_ativacao_pagseguro,
  email_admin,

  enotas_nome_fantasia,
  endereco_complemento,
  enotas_end_complemento,
  enotas_insc_municipal,
  enotas_end_logradouro,
  enotas_end_numero,
  enotas_end_bairro,
  enotas_end_nome_municipio,
  enotas_end_uf,
  enotas_end_cep,
  enotas_end_cod_ibge_cidade,
  enotas_telefone_comercial,
  enotas_optante_simples,
  enotas_id_empresa,
  enotas_razao_social,
  enotas_cnpj,
  enotas_ie,
  pagseguro_email,
}) {
  return new Promise(async (resolve, reject) => {
    const user = LocalStorageObject.currentUser.get();

    microservice
      .post("baladas", {
        email: user.email,
        token: user.token_login,
        nome_balada,
        id_cidade,
        endereco_bairro,
        endereco_numero,
        endereco_logradouro,
        endereco_cep,
        pagseguro_pub_key,
        codigo_ativacao_pagseguro,
        email_admin,

        enotas_nome_fantasia,
        endereco_complemento,
        enotas_end_complemento,
        enotas_insc_municipal,
        enotas_end_logradouro,
        enotas_end_numero,
        enotas_end_bairro,
        enotas_end_nome_municipio,
        enotas_end_uf,
        enotas_end_cep,
        enotas_end_cod_ibge_cidade,
        enotas_telefone_comercial,
        enotas_optante_simples,
        enotas_id_empresa,
        enotas_razao_social,
        enotas_cnpj,
        enotas_ie,
        pagseguro_email,
      })
      .then((result) => resolve(result.data))
      .catch((err) => {
        reject(err.response);
      });
  });
}

export async function updateCompany({
  id,
  nome_balada,
  id_cidade,
  endereco_cep,
  endereco_bairro,
  endereco_logradouro,
  endereco_numero,
  codigo_ativacao_pagseguro,
  pagseguro_pub_key,
  email_admin,
  enotas_nome_fantasia,
  endereco_complemento,
  enotas_end_complemento,
  enotas_insc_municipal,
  enotas_end_logradouro,
  enotas_end_numero,
  enotas_end_bairro,
  enotas_end_nome_municipio,
  enotas_end_uf,
  enotas_end_cep,
  enotas_end_cod_ibge_cidade,
  enotas_telefone_comercial,
  enotas_optante_simples,
  enotas_id_empresa,
  enotas_razao_social,
  enotas_cnpj,
  enotas_ie,
  pagseguro_email,
}) {
  return new Promise(async (resolve, reject) => {
    const user = LocalStorageObject.currentUser.get();

    microservice
      .put("baladas/" + id, {
        email: user.email,
        token: user.token_login,
        nome_balada,
        id_cidade,
        endereco_bairro,
        endereco_numero,
        endereco_logradouro,
        endereco_cep,
        pagseguro_pub_key,
        codigo_ativacao_pagseguro,
        email_admin,
        enotas_nome_fantasia,
        endereco_complemento,
        enotas_end_complemento,
        enotas_insc_municipal,
        enotas_end_logradouro,
        enotas_end_numero,
        enotas_end_bairro,
        enotas_end_nome_municipio,
        enotas_end_uf,
        enotas_end_cep,
        enotas_end_cod_ibge_cidade,
        enotas_telefone_comercial,
        enotas_optante_simples,
        enotas_id_empresa,
        enotas_razao_social,
        enotas_cnpj,
        enotas_ie,
        pagseguro_email,
      })
      .then((result) => resolve(result.data))
      .catch((err) => {
        reject(err.response);
      });
  });
}
