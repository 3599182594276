const apiBaseURL = "https://api.tickerup.com.br/";
const geoDataBaseURL = "https://api.opencagedata.com/geocode/v1/";
const geoDataApiKey = "c6b92689205f49ff8dd29eec6942d36a";
const microserviceUrl = "https://microservice.tickerup.com.br/";
//const microserviceUrl = "http://143.198.226.186/";
export default {
  apiBaseURL,
  apiImageURL: `${apiBaseURL}public/`,
  geoDataBaseURL,
  geoDataApiKey,
  microserviceUrl,
};
