const getObj = function (key) {
  try {
    let rawObj = localStorage.getItem(key);
    rawObj = JSON.parse(rawObj);
    return rawObj;
  } catch (error) {
    return false;
  }
};

const setObj = function (key, obj) {
  try {
    localStorage.setItem(key, JSON.stringify(obj));
    return true;
  } catch (error) {
    return false;
  }
};

const destroyObj = function (key) {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (error) {
    return false;
  }
};

const LocalStorageObject = {
  clear() {
    localStorage.clear();
  },
  currentUser: {
    get() {
      return getObj("tickerup:currentUser");
    },
    set(obj) {
      return setObj("tickerup:currentUser", obj);
    },
    remove() {
      return destroyObj("tickerup:currentUser");
    },
  },
  currentCompany: {
    get() {
      return getObj("tickerup:currentCompany");
    },
    set(obj) {
      return setObj("tickerup:currentCompany", obj);
    },
    remove() {
      return destroyObj("tickerup:currentCompany");
    },
  },
  companies: {
    get() {
      return getObj("tickerup:companies");
    },
    set(obj) {
      return setObj("tickerup:companies", obj);
    },
    remove() {
      return destroyObj("tickerup:companies");
    },
  },
  users: {
    get() {
      return getObj("tickerup:users");
    },
    set(obj) {
      return setObj("tickerup:users", obj);
    },
    remove() {
      return destroyObj("tickerup:companies");
    },
  },
  menuItems: {
    get() {
      return getObj("tickerup:menuItems");
    },
    set(obj) {
      return setObj("tickerup:menuItems", obj);
    },
    remove() {
      return destroyObj("tickerup:menuItems");
    },
  },
  terminals: {
    get() {
      return getObj("tickerup:terminals");
    },
    set(obj) {
      return setObj("tickerup:terminals", obj);
    },
    remove() {
      return destroyObj("tickerup:terminals");
    },
  },
  clients: {
    get() {
      return getObj("tickerup:clients");
    },
    set(obj) {
      return setObj("tickerup:clients", obj);
    },
    remove() {
      return destroyObj("tickerup:clients");
    },
  },
};

export default LocalStorageObject;
