import FeatherIcon from "feather-icons-react";
import React, { useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import LogoImg from "../../../assets/logo.png";
import { getUser, login } from "../../../providers/auth";
import { getCompanies, getGeneralCompanies } from "../../../providers/company";
import LocalStorageObject from "../../../providers/localStorageObject";
import {
  Button,
  ButtonLoading,
  CardContainer,
  Container,
  FormLogin,
  Input,
  Label,
  Logo,
  Title,
} from "./styles";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);

  const alert = useAlert();
  let history = useHistory();

  function handleSubmit(e) {
    e.preventDefault();
    _login();
  }

  async function _login() {
    try {
      setLoading(true);
      const userLogin = await login(email, password);
      if (!userLogin) throw new Error("Usuário/Senha incorretos");
      const user = await getUser(email, userLogin.token);
      if (!user) throw new Error("Erro ao carregar o usuário");
      if (user.id_perfil_usuario === "2") {
        LocalStorageObject.currentUser.set(user);
        const companies = await getCompanies();
        if (!companies) throw new Error("Erro ao carregar empresas");
        LocalStorageObject.companies.set(companies);
        LocalStorageObject.currentCompany.set(companies[0]);
        history.replace("/");
        return;
      }
      if (user.id_perfil_usuario === "12") {
        LocalStorageObject.currentUser.set(user);
        const companies = await getGeneralCompanies();
        if (!companies) throw new Error("Erro ao carregar empresas");
        LocalStorageObject.companies.set(companies);
        LocalStorageObject.currentCompany.set(companies[0]);
        history.replace("/");
        return;
      }
      throw new Error("Esse usuário não tem permissão para acessar o sistema");
    } catch (error) {
      alert.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  return (
    <Container>
      <CardContainer>
        <Logo src={LogoImg} width="300px" />
        <FormLogin id="loginForm" onSubmit={handleSubmit}>
          <Title>Acesso ao sistema</Title>
          <Label>Email</Label>
          <Input
            type="email"
            id="email"
            name="email"
            required={true}
            onChange={(event) => setEmail(event.target.value)}
          />
          <Label>Senha</Label>
          <Input
            type="password"
            id="password"
            name="password"
            required={true}
            onChange={(event) => setPassword(event.target.value)}
          />
          {(() => {
            if (loading) {
              return (
                <ButtonLoading type="button">
                  <FeatherIcon
                    className="App-icon"
                    icon="refresh-cw"
                    size={14}
                  />
                </ButtonLoading>
              );
            }
            return <Button type="submit">Login</Button>;
          })()}
        </FormLogin>
      </CardContainer>
    </Container>
  );
};
export default Login;
