import React, { useEffect, useState } from "react";
import { PageHeader, PageTitle } from "../../global/styles";
import { Td, TdNumber, Th, ThNumber } from "./styles";

const ReportSalesPayment = ({ rawPaymentData }) => {
  const [items, setItems] = useState([]);
  const [totalValue, setTotalValue] = useState(0);
  const [totalQty, setTotalQty] = useState(0);

  const formatter = new Intl.NumberFormat([], {
    style: "currency",
    currency: "BRL",
  });

  useEffect(() => {
    let products = [];
    let total = 0;
    let totalQty = 0;

    if (!rawPaymentData) return;

    rawPaymentData.map((product) => {
      totalQty += 1;

      total += product.valor_produto;
      // Get data per product
      const indexProduct = products.findIndex(
        (sale) => sale.tipo_pagamento === product.tipo_pagamento
      );
      if (indexProduct === -1) {
        products.push({
          tipo_pagamento: product.tipo_pagamento,
          quantidade: 1,
          total: product.valor_produto,
        });
      } else {
        products[indexProduct].quantidade += 1;
        products[indexProduct].total += product.valor_produto;
      }
      return true;
    });

    setTotalQty(totalQty);
    setTotalValue(total);
    setItems(products);
  }, [rawPaymentData]);

  return (
    <>
      <PageHeader>
        <PageTitle>Produtos por forma de pagamento</PageTitle>
      </PageHeader>
      <table>
        <thead>
          <tr>
            <Th>Forma Pagamento</Th>
            <ThNumber>Qtd</ThNumber>
            <ThNumber>Valor</ThNumber>
          </tr>
        </thead>
        <tbody>
          {items.map((productItem) => {
            return (
              <tr key={productItem.tipo_pagamento}>
                <Td>{productItem.tipo_pagamento}</Td>
                <TdNumber>{productItem.quantidade}</TdNumber>
                <TdNumber>{formatter.format(productItem.total)}</TdNumber>
              </tr>
            );
          })}
          <tr>
            <Td>Total</Td>
            <TdNumber>{totalQty}</TdNumber>
            <TdNumber>{formatter.format(totalValue)}</TdNumber>
          </tr>
        </tbody>
      </table>
    </>
  );
};
export default ReportSalesPayment;
