import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import LogoImg from "../../assets/logo_top.png";
import config from "../../config";
import LocalStorageObject from "../../providers/localStorageObject";
import {
  Container,
  Logo,
  MenuLi,
  MenuUl,
  UserContainer,
  UserData,
  UserEmail,
  UserMenuContainer,
  UserName,
  UserPhoto,
} from "./styles";

const TopMenu = () => {
  const [user, setUser] = useState({
    email: "",
    nome: "",
    photo: "",
  });
  const [showMenu, setShowMenu] = useState(false);

  const history = useHistory();

  useEffect(() => {
    _loadUser();
  }, []);

  async function _loadUser() {
    let currentUser = LocalStorageObject.currentUser.get();
    if (currentUser) {
      currentUser.photo = `${config.apiImageURL}${currentUser.url_foto}`;
      setUser(currentUser);
    }
  }

  function toggleUserMenu() {
    setShowMenu(!showMenu);
  }

  function logout() {
    setShowMenu(false);
    LocalStorageObject.clear();
    history.replace("/login");
  }

  return (
    <Container>
      <Logo src={LogoImg} />
      {(() => {
        if (user.nome)
          return (
            <UserContainer onClick={toggleUserMenu}>
              <UserData>
                <UserName>{user.nome}</UserName>
                <UserEmail>{user.email}</UserEmail>
              </UserData>
              <UserPhoto src={user.photo} />
            </UserContainer>
          );
      })()}

      {(() => {
        if (showMenu)
          return (
            <UserMenuContainer>
              <MenuUl>
                <MenuLi onClick={logout}>Sair</MenuLi>
              </MenuUl>
            </UserMenuContainer>
          );
      })()}
    </Container>
  );
};
export default TopMenu;
