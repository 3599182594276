/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import Datatable from "../../../../components/datatable";
import LoadBar from "../../../../components/loadBar";
import VerifyCurrentUser from "../../../../components/verifyCurrentUser";
import {
  ButtonDefault,
  ColumnLayout,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../global/styles";
import { getClients } from "../../../../providers/client";
import LocalStorageObject from "../../../../providers/localStorageObject";
import { Container } from "./styles";

const ClientList = () => {
  const alert = useAlert();

  const [loading, setLoading] = useState(false);
  const [clients, setClients] = useState([]);

  useEffect(() => {
    const response = LocalStorageObject.clients.get();
    if (response) {
      setClients(response);
    }
    _reloadClients();
  }, []);

  const _reloadClients = async () => {
    try {
      setLoading(true);
      const response = await getClients();
      if (response) {
        console.log("response", response);
        setClients(response);
        LocalStorageObject.clients.set(response);
      }
    } catch (error) {
      alert.error("Erro ao buscar dados dos terminais");
    } finally {
      setLoading(false);
    }
  };

  const columns = useMemo(() => [
    {
      Header: "Nome",
      accessor: "nome",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Documento",
      accessor: "cpf_cnpj",
    },
    {
      Header: "Telefone",
      accessor: "telefone",
    },
    {
      Header: "Empresa",
      accessor: "nome_balada",
    },
  ]);

  return (
    <>
      <Container>
        <PageContainer className="printable">
          <PageHeader>
            <PageTitle>Clientes</PageTitle>
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <ButtonDefault
                onClick={() => {
                  window.print();
                }}
              >
                Imprimir
              </ButtonDefault>
            </LayoutCell>
            <LayoutCell></LayoutCell>
          </ColumnLayout>
          <Datatable columns={columns} data={clients} />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};

export default ClientList;
