import microservice from "../services/microservice";
import LocalStorageObject from "./localStorageObject";

export async function getAllTerminals() {
  try {
    const response = await microservice.get("terminals");
    return response.data;
  } catch (error) {
    return [];
  }
}

export async function createTerminal({ companyId, serial }) {
  return new Promise((resolve, reject) => {
    let user = LocalStorageObject.currentUser.get();
    microservice
      .post("terminals", {
        companyId,
        serial,
        email: user.email,
        token: user.token_login,
      })
      .then(() => {
        resolve(true);
      })
      .catch((err) => {
        reject(err.response.data);
      });
  });
}
