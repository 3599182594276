/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import FeatherIcon from "feather-icons-react";
import React, { useCallback, useRef, useState } from "react";
import { useAlert } from "react-alert";
import "react-datetime/css/react-datetime.css";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { useHistory } from "react-router-dom";
import SearchAddress from "../../../../../components/searchAddress";
import VerifyCurrentUser from "../../../../../components/verifyCurrentUser";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  HiddenFileInput,
  InputText,
  InputTextArea,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  PrimaryPainelBody,
  PrimaryPainelHeader,
  RequiredLabel,
} from "../../../../../global/styles";
import theme from "../../../../../global/theme";
import { saveCategory } from "../../../../../providers/productCategory";
import { Container, DateSelect } from "./styles";

const EventAdd = () => {
  const [loading, setLoading] = useState(false);
  const [upImg, setUpImg] = useState();
  const [upImgMap, setUpImgMap] = useState();
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 16 / 9 });
  const [cropMap, setCropMap] = useState({
    unit: "%",
    width: 30,
    aspect: 9 / 16,
  });
  const [completedCropMap, setCompletedCropMap] = useState(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const imgRef = useRef(null);
  const imgRefMap = useRef(null);

  const [title, setTitle] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [latitude, setLatitude] = useState("");
  const [longitude, setLongitude] = useState("");
  const [locationName, setLocationName] = useState("");

  const toast = useAlert();
  const history = useHistory();

  function navigateToBack() {
    history.goBack();
  }

  function handleSubmit(e) {
    e.preventDefault();
    _saveEvent();
  }

  async function _saveEvent() {
    try {
      setLoading(true);
      const response = await saveCategory(title);
      if (!response) throw new Error("Erro ao salvar a nova categoria");
      history.goBack();
    } catch (error) {
      toast.error(error.message);
    } finally {
      setLoading(false);
    }
  }

  function onSelectFeatureImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  function onSelectMapImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImgMap(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <>
      <Container onSubmit={handleSubmit}>
        <PageContainer>
          <PageHeader>
            <PageTitle>Adicionar evento</PageTitle>
            <ButtonDefault type="button" onClick={navigateToBack}>
              Voltar
            </ButtonDefault>
            {(() => {
              if (loading) {
                return (
                  <ButtonDefault
                    type="button"
                    style={{ marginLeft: 20, width: 80 }}
                  >
                    <FeatherIcon
                      className="App-icon"
                      icon="refresh-cw"
                      size={14}
                    />
                  </ButtonDefault>
                );
              }
              return (
                <ButtonSuccess
                  type="submit"
                  style={{ marginLeft: 20, width: 80 }}
                >
                  Salvar
                </ButtonSuccess>
              );
            })()}
          </PageHeader>
          <h2 style={{ marginLeft: 10 }}>Informações</h2>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Título <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setTitle(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Descrição <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputTextArea
                onChange={(event) => setTitle(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Endereço <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <SearchAddress
                setState={setState}
                setCity={setCity}
                setLatitude={setLatitude}
                setLongitude={setLongitude}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Estado <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                readOnly={true}
                value={state}
                required={true}
                style={{
                  marginTop: 10,
                  backgroundColor: theme.defaultBackgroundColor,
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Cidade <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                readOnly={true}
                value={city}
                required={true}
                style={{
                  marginTop: 10,
                  backgroundColor: theme.defaultBackgroundColor,
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Latitude <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                readOnly={true}
                value={latitude}
                required={true}
                style={{
                  marginTop: 10,
                  backgroundColor: theme.defaultBackgroundColor,
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>
                Longitude <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                readOnly={true}
                value={longitude}
                required={true}
                style={{
                  marginTop: 10,
                  backgroundColor: theme.defaultBackgroundColor,
                }}
              />
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>
                Nome do local do evento{" "}
                <RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setLocationName(event.target.value)}
                required={true}
                style={{
                  marginTop: 10,
                }}
              />
            </LayoutCell>
          </ColumnLayout>
          <h2 style={{ marginLeft: 10 }}>Datas</h2>
          <ColumnLayout>
            <LayoutCell>
              <LabelText>Data início venda de produtos</LabelText>
              <DateSelect
                onChange={(event) => {
                  console.log(event.format("DD/MM/YYYY HH:mm"));
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data fim venda de produtos</LabelText>
              <DateSelect
                onChange={(event) => {
                  console.log(event.format("DD/MM/YYYY HH:mm"));
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data início do evento</LabelText>
              <DateSelect
                onChange={(event) => {
                  console.log(event.format("DD/MM/YYYY HH:mm"));
                }}
              />
            </LayoutCell>
            <LayoutCell>
              <LabelText>Data fim do evento</LabelText>
              <DateSelect
                onChange={(event) => {
                  console.log(event.format("DD/MM/YYYY HH:mm"));
                }}
              />
            </LayoutCell>
          </ColumnLayout>
          <h2 style={{ marginLeft: 10 }}>Imagens</h2>
          <ColumnLayout>
            <LayoutCell>
              <PrimaryPainelHeader>
                Imagem destaque <RequiredLabel>Obrigatório</RequiredLabel>
              </PrimaryPainelHeader>
              <PrimaryPainelBody>
                <HiddenFileInput
                  id="feature-image"
                  type="file"
                  accept="image/jpeg"
                  onChange={onSelectFeatureImage}
                />
                <ButtonSuccess
                  onClick={() => {
                    document.getElementById("feature-image").click();
                  }}
                  style={{ width: "100%", fontSize: 14 }}
                  type="button"
                >
                  Escolher imagem
                </ButtonSuccess>
                <ReactCrop
                  style={{ marginTop: 10 }}
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                />
              </PrimaryPainelBody>
            </LayoutCell>
            <LayoutCell>
              <PrimaryPainelHeader>Mapa do evento</PrimaryPainelHeader>
              <PrimaryPainelBody>
                <HiddenFileInput
                  id="map-image"
                  type="file"
                  accept="image/jpeg"
                  onChange={onSelectMapImage}
                />
                <ButtonSuccess
                  onClick={() => {
                    document.getElementById("map-image").click();
                  }}
                  style={{ width: "100%", fontSize: 14 }}
                  type="button"
                >
                  Escolher imagem
                </ButtonSuccess>
                <ReactCrop
                  style={{ marginTop: 10 }}
                  src={upImgMap}
                  onImageLoaded={onLoad}
                  crop={cropMap}
                  onChange={(c) => setCropMap(c)}
                  onComplete={(c) => setCompletedCropMap(c)}
                />
              </PrimaryPainelBody>
            </LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
    </>
  );
};
export default EventAdd;
