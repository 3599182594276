/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useRef, useState } from "react";
import ReactCrop from "react-image-crop";
import LoadBar from "../../../../components/loadBar";
import {
  ButtonDefault,
  ButtonSuccess,
  ColumnLayout,
  HiddenFileInput,
  InputText,
  LabelText,
  LayoutCell,
  PageContainer,
  PageHeader,
  PageTitle,
  PrimaryPainelBody,
  PrimaryPainelHeader,
  RequiredLabel,
} from "../../../../global/styles";
import { getCategories } from "../../../../providers/productCategory";
import { Container } from "./styles";

const OwnerProductAdd = () => {
  const [name, setName] = useState("");
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [upImg, setUpImg] = useState();
  const imgRef = useRef(null);
  const [completedCrop, setCompletedCrop] = useState(null);
  const [crop, setCrop] = useState({ unit: "%", width: 30, aspect: 1 / 1 });

  useEffect(() => {
    _loadCategories();
  }, []);

  async function _loadCategories() {
    setLoading(true);
    try {
      const response = await getCategories();
      setCategories(response);
    } catch (error) {
    } finally {
      setLoading(false);
    }
  }

  function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () => setUpImg(reader.result));
      reader.readAsDataURL(e.target.files[0]);
    }
  }

  const onLoad = useCallback((img) => {
    imgRef.current = img;
  }, []);

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Adicionar produto global</PageTitle>
            <ButtonDefault style={{ marginRight: 20, width: 80 }}>
              Voltar
            </ButtonDefault>
            <ButtonSuccess style={{ width: 80 }}>Salvar</ButtonSuccess>
          </PageHeader>
          <ColumnLayout>
            <LayoutCell>
              <br />
              <LabelText>
                Nome<RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setName(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
              <br />
              <LabelText>
                Descrição<RequiredLabel>Obrigatório</RequiredLabel>{" "}
              </LabelText>
              <InputText
                onChange={(event) => setName(event.target.value)}
                required={true}
                style={{ marginTop: 10 }}
              />
            </LayoutCell>

            <LayoutCell>
              <br />
              <PrimaryPainelHeader>
                Imagem <RequiredLabel>Obrigatório</RequiredLabel>
              </PrimaryPainelHeader>
              <PrimaryPainelBody>
                <HiddenFileInput
                  id="feature-image"
                  type="file"
                  accept="image/jpeg"
                  onChange={onSelectImage}
                />
                <ButtonSuccess
                  onClick={() => {
                    document.getElementById("feature-image").click();
                  }}
                  style={{ width: "100%", fontSize: 14 }}
                  type="button"
                >
                  Escolher imagem
                </ButtonSuccess>
                <ReactCrop
                  style={{ marginTop: 10 }}
                  src={upImg}
                  onImageLoaded={onLoad}
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  onComplete={(c) => setCompletedCrop(c)}
                />
              </PrimaryPainelBody>
            </LayoutCell>
          </ColumnLayout>
          <ColumnLayout>
            <LayoutCell></LayoutCell>
          </ColumnLayout>
        </PageContainer>
      </Container>
      {loading && <LoadBar />}
    </>
  );
};

export default OwnerProductAdd;
