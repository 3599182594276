import styled from "styled-components";
import theme from "../../global/theme";

export const Container = styled.div`
  flex: 1;
`;

export const AddressItem = styled.span`
  padding: 10px;
  background-color: ${theme.defaultBackgroundColor};
  cursor: pointer;
  margin-top: 3px;
  border-radius: 5px;
  transition: 0.5s;
  :hover {
    background-color: #cecece;
  }
`;
