import microservice from "../services/microservice";

export async function getAllCities() {
  try {
    const response = await microservice.get("cities");

    return response.data;
  } catch (error) {
    return [];
  }
}
