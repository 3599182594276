import api from "../services/backend";

export async function login(email, senha) {
  try {
    const response = await api.post("usuario/login", {
      email,
      senha,
    });
    if (response.data.reason === "success") {
      return response.data.data;
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}

export async function getUser(email, token) {
  try {
    const response = await api.post("usuario/view", {
      email,
      token,
    });
    if (response.data.reason === "success") {
      return response.data.data.view;
    }
    throw new Error("");
  } catch (error) {
    return false;
  }
}
