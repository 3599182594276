/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import Datatable from "../../../../../components/datatable";
import LoadBar from "../../../../../components/loadBar";
import VerifyCurrentUser from "../../../../../components/verifyCurrentUser";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../../global/styles";
import { getCategories } from "../../../../../providers/productCategory";
import SessionStorageObject from "../../../../../providers/sessionStorageObject";
import { Container } from "./styles";

const ProductCategoryList = () => {
  const [loading, setLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const columns = useMemo(() => [
    {
      Header: "Categoria",
      accessor: "descricao",
    },
    {
      Header: "Ações",
      accessor: "",
    },
  ]);

  const toast = useAlert();
  const history = useHistory();

  useEffect(() => {
    getStorage();
    _loadCategories();
  }, []);

  function edit(id) {
    history.push("/product/category/edit/" + id);
  }

  function remove(id) {
    console.log("id", id);
  }

  function navigateToAdd() {
    history.push("/product/category/add");
  }

  function getStorage() {
    const sessionData = SessionStorageObject.get("bimore:productCategories");
    if (sessionData) setCategories(sessionData);
  }

  async function _loadCategories() {
    try {
      setLoading(true);
      const response = await getCategories();
      SessionStorageObject.set("bimore:productCategories", response);
      getStorage();
    } catch (error) {
      toast.error("Erro ao buscar as categorias");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Categorias de produto</PageTitle>
            <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
          </PageHeader>
          <Datatable
            columns={columns}
            data={categories}
            edit={edit}
            remove={remove}
          />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default ProductCategoryList;
