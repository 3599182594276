/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo, useState } from "react";
import { useAlert } from "react-alert";
import { useHistory } from "react-router-dom";
import Datatable from "../../../../../components/datatable";
import LoadBar from "../../../../../components/loadBar";
import VerifyCurrentUser from "../../../../../components/verifyCurrentUser";
import {
  ButtonSuccess,
  PageContainer,
  PageHeader,
  PageTitle,
} from "../../../../../global/styles";
import { getEvents } from "../../../../../providers/events";
import SessionStorageObject from "../../../../../providers/sessionStorageObject";
import { Container } from "./styles";

const EventList = () => {
  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState([]);
  const columns = useMemo(() => [
    {
      Header: "Nome evento",
      accessor: "titulo_evento",
    },
    {
      Header: "Cidade",
      accessor: "cidade",
    },
    {
      Header: "Ações",
      accessor: "",
    },
  ]);

  const toast = useAlert();
  const history = useHistory();

  useEffect(() => {
    getStorage();
    _loadEvents();
  }, []);

  function edit(id) {
    history.push("/event/edit/" + id);
  }

  function remove(id) {
    console.log("id", id);
  }

  function navigateToAdd() {
    history.push("/event/add");
  }

  function getStorage() {
    const sessionData = SessionStorageObject.get("bimore:events");
    if (sessionData) setEvents(sessionData);
  }

  async function _loadEvents() {
    try {
      setLoading(true);
      const response = await getEvents();
      console.log("response", response);
      SessionStorageObject.set("bimore:events", response);
      getStorage();
    } catch (error) {
      toast.error("Erro ao buscar os eventos");
    } finally {
      setLoading(false);
    }
  }

  return (
    <>
      <Container>
        <PageContainer>
          <PageHeader>
            <PageTitle>Eventos</PageTitle>
            <ButtonSuccess onClick={navigateToAdd}>Adicionar</ButtonSuccess>
          </PageHeader>
          <Datatable
            columns={columns}
            data={events}
            edit={edit}
            remove={remove}
          />
        </PageContainer>
      </Container>
      <VerifyCurrentUser />
      {loading && <LoadBar />}
    </>
  );
};
export default EventList;
